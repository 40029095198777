@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

@media only screen and (max-width: 1200px) {
  #searchButton-home {
    border-radius: 35px;
    width: 100%;
  }
  #searchButton-home_searchCard {
    border-radius: 35px;
    width: 65%;
  }
  #searchButton-home-white {
    border-radius: 45px;
    width: 100%;
  }
  .textField-new::placeholder {
    color: black;
    opacity: 1;
  }
  p.smartVisit-title {
    padding-top: 4%;
    font-size: 30px;
    margin-left: 8%;
    color: #00476b;
    font-weight:bolder;
   font-family: 'Lato', sans-serif;
  }
  p.buy_txt{
    display: none;
  }
  .new-site {
    margin-left: 20px;
  }
  .new-bx-ads-homestyle {
    display: none !important;
  }
  .new-bx-ads-homestyle-mobile {
    display: block;
    background-color: #fff;
    border: 1.2px solid #aeaeae !important;
    border-radius: 60px;
    width: 85%;
    
  }
  .filterIcon {
    background-color: #00AEEC;
    padding: 4.5px;
    color: #fff;
    border-radius: 7px;
    float: right;
    margin-right: 15px;
  }
  .textField-new {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    border: none;
    border-color: transparent;
    background-color: transparent;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1100px) {
  button.brocher-btn {
    border-radius: 7px;
    background-color: #030363;
    border: none;
    color: white;
    padding: 5px 10px;
    height: 50px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }
  
  .brocher-btn-white {
    border-radius: 7px;
    background-color: #00AEEC;
    margin-top: 3%;
  }
  .brocher-btn-virtual {
    border-radius: 5px;
    background-color: #00AEEC;
    border: none;
    color: white;
    padding: 2px 10px;
    height: 45px !important;
    width: 90%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1099px) {
  button.brocher-btn {
    border-radius: 7px;
    background-color: #030363;
    border: none;
    color: white;
    padding: 5px 10px;
    height: 50px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14.5px;
    cursor: pointer;
  }
  
  .brocher-btn-white {
    border-radius: 7px;
    background-color: #00AEEC;
    margin-top: 3%;
  }
  .brocher-btn-virtual {
    border-radius: 7px;
    background-color: #00AEEC;
    border: none;
    color: white;
    padding: 2px 10px;
    height: 50px !important;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
  }
  .brocher-btn-virtual_new {
    border-radius: 7px;
    background-color: #00AEEC;
    border: none;
    color: white;
    padding: 2px 10px;
    height: 45px !important;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    cursor: pointer;
    margin-top: 5px;
  }
  .brocher-btn-virtual_new_blue {
    border-radius: 7px;
    background-color: #030363;
    border: none;
    color: white;
    padding: 2px 10px;
    height: 45px !important;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    cursor: pointer;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1201px) {
  .new-bx-ads-homestyle {
    background-color: #fff;
    border: 1.2px solid #aeaeae !important;
    border-radius: 60px;
    width: 82%;
    padding-top: 4.3px;
    padding-bottom: 7.7px;
    display: block !important;
    margin-left: 9%;
  }
  p.smartVisit-title {
    padding-top: 4%;
    font-size: 40px;
    margin-left: 8.7%;
    color: #00476b;
    font-weight:bolder;
   font-family: 'Lato', sans-serif;
  }
  .textField-new::placeholder {
    color: #888888;
    opacity: 1;
    text-align: center;
  }
  .textField-new {
    width: 85%;
    margin-right: 15%;
    border: none;
    border-color: transparent;
    background-color: transparent;
    margin-top: 5.8px;
  }
  p.buy_txt{
    color: grey;
    font-size: 20px;
    margin-left: 11.5%;
    display: block;
  }
  .new-bx-ads-homestyle-mobile {
    display: none !important;
  }
  .searchCard-dividerRight {
    border-right: 1.7px solid #00AEEC;
    padding: 0px !important;
    margin: 0px !important;
  }
  .searchCard-dividerRight_First{
    border-right: 1.7px solid #00AEEC;
    width: 95%;
    margin-left: 16%;

  }
  .searchCard-dividerRight-Input {
    border-right: 1.7px solid #00AEEC;
    margin-top: 9px;
    height: 40px;
  }
}
.options-select {
  overflow: ellipsis
}
#searchButton-home {
  background-color: #00AEEC;
  border-radius: 10px;
  height: 40px;
  font-size: 0.95em !important;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-top: 8.3px;
  width: 100% !important;
  padding-top: 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
}
button.searchButton-home_searchCard {
  width: 65% !important;
  height: 37px;
  border-radius: 5px !important;
  color: white;
  font-size: 0.95em !important;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-left: 5%;
  background-color: #00AEEC;
  filter: drop-shadow(5px 10px 7px rgba(255, 243, 243, 0.25));
  margin-top: 8.3px;
  padding-top: 5px;
  transition-duration: 0.5s;
  margin-right: 17.5%;
  justify-content: center;
  align-items: center;
  align-self: center;
}
button.searchButton-home_searchCard:hover{
  background-color: #193f6a;
  color: #fff !important;
}
#searchButton-home-white {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  color: #00AEEC !important;
  border-color: #00AEEC;
  border-width: 1.5px;
  height: 40px;
  font-size: 0.95em !important;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
}
#searchButton-home:hover {
  color: white !important;
  background-color: #02486c !important;
}
#searchButton-home-white:hover {
  background-color: #02486c !important;
  color: #fff !important;
}
.newSettings {
  margin-top: auto;
  margin-bottom: auto;
}
