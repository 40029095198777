 

     .agri-header{
 
height: 157px;
left: 18px;
gap: 0px;
border-radius: 0px 0px 23px 23px;
background: linear-gradient(6.35deg, #BDD45F 3.56%, #79932C 34.92%, #405C00 77.35%);
display: flex;
justify-content: center;
align-items: center;
h1{
    font-family: Inria Serif;
font-size: 45px;
font-weight: 700;
line-height: 53.96px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;

}
}
.dark-green{
    background: #344F00;

}

.light-green{
    background: #7A931D;


}
.details-card{
    width:100%;
    height: 114px;
    
    border-radius: 13px ;
    
    .card-body{

     p{

        font-family: Inria Serif;
font-size: 16px;
font-weight: 700;
line-height: 19.18px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

     }   
    }
}
.agri-Explore{
    background-image: url(./Rectangle\ 564.png);
    min-height: 804px;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-wrap: wrap;
.Explore1{
    width: 100%;
}
}
.bg-light-green{

    background: #D9F179 !important ;
    box-shadow: 0px -3px 12px 3px #D9F179BA !important;
z-index: 1;
}
.bg-drak-green{

    background: #558B2F !important ;
    box-shadow: 0px -3px 12px 3px #D9F179BA !important;
z-index: 1;
}
.cilp-pill{


    width: 251px;
height: 60px;
justify-content: space-between;
 border-radius: 0px 25px 25px 0px;
 background: #4F7800;

}
.cilp-pill>.aaaa{
    position: relative;
    p{
        position: absolute;
        top: 4px;
        left: 15%;
        font-family: Kadwa;
font-size: 23px;
font-weight: 700;
line-height: 46.18px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #FFFFFF;
    }
}

.pera-1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    
}
.pera-1>p{
    font-family: Inria Serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 23.98px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0px;
color: #FFFFFF;    
}
.carousel-item{
    img{
        height: 410px;
    }
}
.Explore1{
  h1{
    font-family: Inria Serif;
font-size: 30px;
font-weight: 700;
line-height: 35.97px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000000;

  }  
}
.carousel-inner {
    position: relative;
    width: 100%;
    border-radius: 10px !important;
    overflow: hidden;
}

.carousel-indicators{

    position: absolute;
    right: 10% !important;
    bottom: 0px !important;
     left: unset !important;
    z-index: 2;
    border-radius: 10px 10px 0px 0px;
    background: white;
    width: 106px;
    display: flex;
    justify-content: center;
    padding: 4px;
    margin-right: 0 !important; 
         margin-bottom: 0 !important;
      margin-left: 0 !important;
    list-style: none;
}

.carousel-indicators button{

    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    padding: 0px;
    margin-right: 3px;
    margin-left: 3px;
    margin: 0px;
    text-indent: -999px;
    cursor: pointer;
    background-color: forestgreen !important;
    background-clip: padding-box;
    border: 3px solid white;
      border-top:  0px solid #ffffff00 !important; 
      border-bottom:  0px solid transparent !important;  
    opacity: .5;
    transition: opacity .6s ease;
}
.col-12-bottom{
    display: flex;
    justify-content: center;
}

.lightgreen{
    background: #DBEFC5 !important;

}
.agir-Documents{

    
 min-height: 638px;
 background-image: url(./backgrundSvg.svg);
 background-size: cover;
 background-repeat: no-repeat;
 display: flex;
 justify-content: center;
 align-items: center;
flex-wrap: wrap;

}
.sub-2-div{
 
    height: 352.52px;
    width: 317px;
    /* background-image: url(./divSVG.svg); */
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Additional-title{


    font-family: Inria Serif;
font-size: 30px;
font-weight: 700;
line-height: 35px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #000000;

}

.prag-add{
    font-family: Inria Serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #3B5305 !important;
    
}

.doc-item {
    max-width: 225px;
    height: 64px;
    border: 0.5px solid #868181;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding-left: 10px;

  }




  @media screen and (max-width: 451px) {
    .agri-header {
    h1{

        font-family: Inria Serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 23.98px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        
    }
}  /* Add your styles here */
  }
  

  @media screen and (max-width: 1201px) {
      /* Add your styles here */

      .agir-Documents{
        .sub-2-div{
            top: 0px !important;

        }
      }
  }