@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

@media screen and (max-width: 767px) {
    .cards-status-inner{
        padding: 2.5%;
        padding-left: 7%;
        border: 2px solid #eeeeee;
        border-radius: 10px; 
        margin-bottom: 4%;
        width:100%; 
        height:auto;
    }
    .container-status{
        display:block;
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
        background-repeat: no-repeat, repeat;
        background-position: right top;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}
@media screen and (min-width: 767px) {
    .cards-status-inner{
        padding: 2.5%;
        padding-left: 5%;
        padding-right: 5%;
        border: 2px solid #eeeeee;
        border-radius: 10px; 
        margin-bottom: 4%;
        width:90%; 
        height:auto;
    }
    .container-status{
        display:block;
        width: 80%;
        margin-left: 10%;
        margin-top: 5%;
        background-repeat: no-repeat, repeat;
        background-position: right top;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}
.virtualimage{
    height: 50px;
    width: 150px;
}

.statusimage{
    height: 19px;
    width: 19px;
}
.star{
    margin-left: 15px;
}
.addimage{
    height: 19px;
    width: 19px;
}
.simage{
   height:16px ;
   width: 15px;
}
.sc1{
    cursor: pointer;
    font-size: 13px;
    color: #193f6a;
    font-family: 'Lato', sans-serif;
    margin-bottom: -11px;
}
.gimage{
    height:20px;
    width: 20px;
    margin-left: 20px;
    margin-top: 15px;
}
.trimage{
    height: 18px;
    width: 18px;
}
.search{
    border-radius: 30px;
    float: right;
    margin-top:4%;
    width :50%; 
    margin-right: -40px; 
}

.r1 { 

    display:flex; 
    flex-wrap: wrap;
}
.btn1{
    float: right;
    border-radius: 60px;
    padding: 3px 7px 3px 6px;
    color: white;
    font-size: 14px;
    background: #193f6a; 
    margin-left: 1px;
    width: 30%;

}

.subtitle-content{
    font-size: 18px;
    color: #02486c !important;
    margin-bottom: 0px;
}
.title-content{
     font-size: 15px;
     font-family: 'Lato', sans-serif;
     margin-bottom: 0px;
     color: #222;
}
.col1{   
    padding-left: 10%;
}
.status-search{
    margin-bottom: 5%;
}
.divTitleBoxAlign{
    margin-left: 7px;
    margin-bottom: 2%;
}
.cards-status{
    padding: 5%;
    border: 2px solid #eeeeee;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 25px; 
    margin-bottom: 4%;
    width:auto; 
    height:auto;
}

button.requestQuoteRenew {
    width: 100%;
    height: 40px;
    border-radius: 0px;
    color: white;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    margin-top: 10px;
    background: #00AEEC;
    cursor: pointer;
    font-weight: 500;
  }
  button.requestQuoteEdit {
    width: 100%;
    height: 40px;
    border-radius: 0px;
    color: #00AEEC;
    border: 1.5px solid #00AEEC;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    margin-top: 10px;
    background: #fff;
    font-weight: 500;
  }
.cards-status-inner-market{
    padding: 2.5%;
    padding-left: 10%;
    width:90%; 
    height:auto;
    cursor: pointer;
}
.cards-status-inner-active{
    padding-left: 5px;
    padding-bottom: 2.5%;
    width:100%; 
}
.property-details-txt{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.cards-status:hover{
    box-shadow: hsla(200, 100%, 67%, 0.9) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.nextIconX{
    color: #193f6a;
    margin-right: 5px;
}
.nextIconX-active{
    color: #36c736;
    margin-right: 5px;
}