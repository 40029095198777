$sidebar-bg-color: #00476b !default;
$sidebar-color: #00476b !default;
$icon-bg-color: #00476b !default;
$sidebar-width: 71.7vw !default;
$highlight-color: #00476b !default;
$sidebar-collapsed-width: 0px !default;
$submenu-bg-color: #00476b !default;
$submenu-indent: 38px !default;
$submenu-bg-color-collapsed: #00476b !default;
$icon-size: 38px !default;
$submenu-indent: 30px !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";
