@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
.connect{
    margin-top: 40px;
    background-color: aqua;
    color: black;
    font-size: 50px;
}
.contact-div1-title-ct{
    color:#fff;
    font-weight: 600;
    font-size: 30px;
    padding-top: 8%;
    font-family: 'Lato', sans-serif;
}

.builder-div1-subtitle-ct{
    color:#fff;
    font-weight: 600;
    font-size: 15px;
    opacity: 0.7;
    margin-top: -10px;
    font-family: 'Lato', sans-serif;
}
.contact-div1-subtitle-ct{
    color:#fff;
    font-size: 15px;
    padding-top: 3px;
    padding-right: 10%;
    font-family: 'Lato', sans-serif;
}
.contact-icon-first-home-ct{
    margin-top: 10% !important;
    margin-left: 4%;
}
.loc-icons-ct{
    color:#fff;
    cursor: pointer;
}
.contact-div1-subtitle-home-ct{
    color:#fff;
    font-size: 16px;
    padding-top: 3px;
    padding-right: 10%;
    font-family: 'Lato', sans-serif;
}
.contact-icon-second-home-ct{
    margin-left: 4%;
    margin-top: 3.5%;
}
.number-2{
    margin-top: -4px;
    font-weight: 600;
    cursor: pointer;
}
.sc-hm-one-in-ct {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(19, 19, 17, 0.8);
    padding-left: 13px;
    padding-bottom: 3%;
  }
.office-img{
    height: 75px;
    width: 75px;
    object-fit: contain;
}
@media screen and (max-width: 767px) {
    .why-us-contact-ct{
        padding: 0px;
        height: 100%;
        background-color: #00AEEC;
    }
    .contact-div-first-home-ct{
        background: #00AEEC ! important;
        border-radius: 10px;
        height: 100%;
        padding-bottom: 13% !important;
        padding-left: 10% !important;
        padding-right: 10% !important;
        padding-top: 13%;
      }
      .sc-hm-one-contact-ct {
        background-repeat: no-repeat, repeat;
        background-position: left top;
        background-image: url("../../assets/RectangleFront3.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100% !important;
      }
      .home-contact-inner-ct{
        padding-top: 20%;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 20%;
      }
      .builder-div1-title-ct{
        color:#fff;
        font-weight: 600;
        font-size: 20px;
        font-family: 'Lato', sans-serif;
    }
}
@media screen and (min-width: 767px) {
    .why-us-contact-ct{
        margin-top: 5px;
        padding: 0px;
        height: 100vh;
        background-color: #00AEEC;
    }
    .builder-div1-title-ct{
      color:#fff;
      font-weight: 600;
      font-size: 30px;
      font-family: 'Lato', sans-serif;
  }
    .contact-div-first-home-ct{
        background: #00AEEC;
        border-radius: 10px;
        height: 89.7vh !important;
        padding-left: 13% !important;
        padding-top: 13%;
    }
    .home-contact-inner-ct{
        padding-top: 13%;
        padding-left: 18%;
        padding-right: 18%;
      }
    .sc-hm-one-contact-ct {
        background-repeat: no-repeat, repeat;
        background-position: left top;
        background-image: url("../../assets/RectangleFront3.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100vh !important;
      }
}
.in-cus-ct-ct {
    background: #E8E8E8;
    border-radius: 7px;
    padding-left: 10px;
  }
  input.input_ct {
    /* background: red; */
    background: #f2ecec;
    border-radius: 7px;
    padding-left: 18px;
    border: none;
    width: 95%;
    min-height: 47px;
  }
  .input_ct {
    background: #E8E8E8;
    border-radius: 7px;
    padding-left: 19px !important;
    border: none;
    width: 95%;
    min-height: 47px;
    margin-bottom: 7%;
    padding-right: 20px !important;
  }
  textarea.messageInput-ct {
    background: #F1F1F1;
    border-radius: 7px;
    padding-left: 18px;
    border: none;
    width: 95%;
    padding-top: 10px;
    min-height: 100px;
  }
  button.requestQuote-homecontact-ct {
    width: 165px;
    height: 40px;
    border-radius: 7px;
    color: white;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    margin-top: 7%;
    background: #00AEEC;
  }
