@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@media only screen and (max-width: 780px) {
  .form-e {
    margin-top: 0px !important;
    border-radius: 4px !important;
  }
}
@media only screen and (min-width: 900px) {
  .form-e {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}
.Modal {
  overflow: hidden;
}
.modalHeader {
  background-color: #363636;
  height: 85px;
}
.dropdown-item-button {
  width: 100%;
  background-color: #193f6a;
}
a {
  color: #fff !important;
  font-weight: 600;
}
a:hover {
  color: #fff !important;
}
@media only screen and (max-width: 629px) {
  .form-e {
    margin-top: 0%;
  }
}
.form-e {
  margin-top: -17%;
}
.form-e {
  background: white;
  mix-blend-mode: normal;
  border: 2px solid #decfcf;
  box-sizing: border-box;
  box-shadow: 0px 18px 26px rgb(74 63 63 / 25%);
  border-radius: 57px;
  /* margin-top: 29
px
; */
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}
p.send-mssge {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* line-height: 45px; */
  /* display: flex; */
  /* align-items: center; */
  letter-spacing: 0.01em;
  color: #0885C5;
  text-align: center;
}
#selectbox {
  border-radius: 25px;
  padding: 20px;
  width: 200px;
  height: 15px;
  width: 400px;
}
@media only screen and (min-width: 768px) {
  .form-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  #mobileService {
    display: none;
  }
  #mobileService-img {
    display: none;
  }
  #mobileYT {
    display: none;
  }
  #deskstopYT {
    display: block;
  }
  .bookModal {
    margin-left: 6%;
    margin-bottom: 5%;
  }
  .ExtraHeightOnDesktop {
    margin-top: 8%;
  }
  .roundImageTop {
    height: 300px;
    width: 125%;
  }
  .verticalLine {
    padding-left: 10%;
    margin-top: 0px !important;
  }
  .text-pop {
    margin-top: 15%;
  }
  .createOrder_bg_mobileMargins{
    margin-left: 7%;
    margin-right: 7%;
  }
  .createOrder_bg{
    background-repeat: no-repeat, repeat;
    background-position: left top;
    background-image: url("../../assets//images/serv2.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 99%;
    min-height: 300px;
    padding: 15px;
    padding-top: 20%;
    margin-left: 0.5%;
  }
  .createOrder_bg_inner{
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 3%;
  }
  .smallVideosOrder{
    height: 140px !important;
    margin-top: 20%;
  }
  .largeVideosOrder{
    height: 180px !important;
    margin-top: 11%;
  }
  .payment-txt {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.01em;
    color: #222;
    text-align: left;
    padding-top: 3%;
  }
  .nextIconNew {
    float: right;
    margin-top: 4.4%;
    cursor: pointer;
    color: #0885C5;
  }
  .nextIcon {
    float: right;
    margin-top: 4.6%;
    cursor: pointer;
    color: #0885C5;
  }
}

@media screen and (max-width: 767px) {
  .createOrder_bg_mobileMargins{
    margin-left: 0%;
    margin-right: 5%;
  }
  .createOrder_bg_mobileNewMargins{
    margin-left: 5%;
  }
  .smallVideosOrder{
    height: 70px !important;
    margin-top: 30%;
  }
  .payment-txt {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.01em;
    color: #222;
    text-align: left;
    padding-top: 4.7%;
  }
  .largeVideosOrder{
    height: 110px !important;
    margin-top: 14%;
  }
  .createOrder_bg{
    background-repeat: no-repeat, repeat;
    background-position: left top;
    background-image: url("../../assets//images/serv2.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    padding: 15px;
    padding-top: 20%;
  }
  .createOrder_bg_inner{
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 4%;
  }
  .txt-in {
    display: none;
  }
  .ExtraHeightOnDesktop {
    margin-top: 10%;
  }
  .timePicker {
    width: 96%;
    margin-left: 2%;
  }
  .inputs {
    width: 86vw;
    margin-left: 7vw;
    margin-right: 7vw;
  }
  #myElement3 {
    display: none;
  }
  #mobileService {
    display: block;
    margin-top: 7%;
  }
  #mobileService-img {
    display: block;
  }
  #mobileYT {
    display: block;
  }
  #deskstopYT {
    display: none;
  }
  .nextIconNew {
    float: right;
    margin-top: 25%;
    cursor: pointer;
    color: #0885C5;
  }
  .nextIcon {
    float: right;
    margin-top: 11%;
    cursor: pointer;
    color: #0885C5;
  }
}


p.createOrder_bg_txt{
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  color: #000;
  font-size: 20px;
  margin-bottom: 0px;
}
button.createOrder_bg_btn{
  width: 100%;
  border-radius: 100px;
  background-color: #00AEEC;
  margin-top: 4%
}
p.ct-frm-lbl {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #3f3a3a;
  margin-bottom: -3px;
}
.in-cus-ct {
  background: #f2ecec;
  border-radius: 25px;
  padding-left: 10px;
}
.book-input {
  border-radius: 5px;
}
#marginInputs {
  margin-top: 10px;
}
.messageInput {
  background: #f2ecec;
  border-radius: 25px;
  padding-left: 18px;
  border: none;
  width: 99%;
  padding-top: 5px;
  min-height: 73px;
}
.input_ {
  background: #f2ecec;
  border-radius: 25px;
  padding-left: 19px !important;
  border: none;
  width: 99%;
  padding-top: 5px;
  min-height: 43px;
  margin-top: 10px;
  padding-right: 20px !important;
}
.selectCity {
  margin-top: 5.2%;
}
.selectTime {
  width: 100%;
  margin-top: 15px;
}

input.bookinput_ {
  /* background: red; */
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #bfbfbf;
  padding-top: 5px;
  min-height: 43px;
  margin-top: 3%;
}
.chbk-icons{
  color: #00AEEC;
  margin-top: -3px;
  margin-left: 0px !important;
}
input.createOrderInput{
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #bfbfbf;
  min-height: 48px;
  margin-top: 28px;
}
input.searchOrderInput{
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #bfbfbf;
  min-height: 45px;
}
input.createOrderInputFirst{
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #bfbfbf;
  min-height: 48px;
}
input.createOrderInputDisabled{
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #E1E1E1;
  min-height: 48px;
  margin-top: 28px;
  background-color: #EDEDED;
  color: #000 !important;
}
textarea.createOrderMessage{
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #bfbfbf;
  padding-top: 10px;
  min-height: 95px;
  margin-top: 25px;
}
input.createOrderInput:focus {
  border: 1.5px solid #bfbfbf;
}
input.createOrderInputFirst:focus {
  border: 1.5px solid #bfbfbf;
}
input.searchOrderInput:focus {
  border: 1.5px solid #bfbfbf;
}
textarea.createOrderMessage:focus{
  border: 1.5px solid #bfbfbf;
}
input.input_ {
  /* background: red; */
  background: #f2ecec;
  border-radius: 25px;
  padding-left: 18px;
  border: none;
  width: 100%;
  padding-top: 5px;
  min-height: 43px;
}
p.loginErrorTxt {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  letter-spacing: 0.01em;
  color: #d9a054;
}
span.validateErrorTxt {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  letter-spacing: 0.01em;
  color: red;
}
p.register-modal-btn-txt {
  color: #0885C5;
  font-size: 17px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
}
button.requestQuote {
  width: 150px;
  height: 40px;
  border-radius: 8px;
  color: white;
  font-size: 17px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
  background: #0885C5;
  margin-bottom: 10px;
}
button.requestQuote:hover{
  background-color: #193f6a;
}
button.requestQuote2 {
  width: 150px;
  height: 40px;
  color: #0885C5;
  border: 1.5px solid #0885C5;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 8px;
}
span.registerWarning-txt {
  color: #5a564f !important;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
  margin-left: 5px;
}
.warnIcon {
  margin-top: -5px;
  color: red;
}
.textarea_message {
  background: #f2ecec;
  border-radius: 25px;
  padding-left: 19px !important;
  border: none;
  width: 86%;
  padding-top: 5px;
  min-height: 70px;
  margin-top: 10px;
  padding-right: 20px !important;
  font-size: 16px;
}
input.bookinput_:focus {
  border: 1.5px solid #bfbfbf;
}
input:focus,
textarea:focus {
  border: 0px solid #bfbfbf;
  outline-offset: 0px;
  outline: none;
}
button {
  background-color: #e8c99e;
  border: none;
  color: white;
  padding: 7px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 7%;
  cursor: pointer;
  border-radius: 60px;
}
.mnu-ctsrvv {
  background-attachment: revert;
  background-position: initial;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 271px;
  margin-top: 3%;

  padding-top: 7%;
}

.mnu-ctsrvv.one {
  background-image: url(../../assets/images/ctvsr1.png);
  position: relative;
}
.mnu-ctsrvvv {
  position: absolute;
  top: 0;
  left: 0;
  width: 97%;
  height: 95%;
  border-radius: 25px;
  background-color: rgb(83 77 77 / 76%);
  padding-left: 13px;
  text-align: center;
}
p.title-ct-srvv {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  padding-top: 4%;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  /* display: flex; */
  align-items: center;
  letter-spacing: 0.01em;
  color: #fffbfb;
}
p.bk-nwbtn {
  margin-top: -7px;
  cursor: pointer;
  background: #0885C5;
  width: 110px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 60px;
  font-size: 13px;
  color: white;
  padding-left: 9px;
  padding-right: 9px;
  height: 21px;
  padding-top: 1px;
  font-weight: bold;
}
p.txt-ct-srvv {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #f1f1f1;
  margin-top: 26px;
  padding-left: 2%;
  padding-right: 2%;
}
.about_us {
  flex-direction: column;
  margin-left: 7%;
  margin-right: 7%;
}
.txt-about_us {
  color: #bb7005;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: -7px;
}
.info {
  margin-left: 7%;
  margin-right: 7%;
}
.info_txt {
  color: #777676;
  font-size: 18px;
}
.readmore {
  color: #737373;
}
.yt_video {
  margin-top: 3%;
}
.intro {
  margin-left: 10%;
  margin-right: 10%;
}
.intro-div {
  margin-top: 5%;
  flex-direction: row;
  display: flex;
}
.intro-block {
  width: 50%;
  background-color: #454246;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 30px;
}
.intro-block2 {
  width: 50%;
  background-color: #454246;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 30px;
  margin-left: 5%;
}
.intro-title {
  color: #fff;
  font-size: 20px;
}
.intro-txt {
  color: #fff;
  font-size: 16px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.services {
  flex-direction: column;
  margin-left: 4%;
  margin-top: 5%;
}
.services-title {
  color: #bb7005;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: -7px;
}
.services-txt {
  font-size: 12px;
  margin-left: -7px;
}
.paymentIcon {
  height: 35px;
  width: 35px;
}
.contact-us {
  margin-top: 7%;
  margin-left: 4.5%;
  margin-right: 4.5%;
  margin-bottom: 7%;
}
.contact-us-title {
  flex-direction: column;
}
p.choosePayment {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.01em;
  color: #0885C5;
  text-align: left;
}
.paymentView {
  border: 1.5px solid #bfbfbf;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  padding-left: 3%;
  padding-top: 1.6%;
  padding-right: 3%;
  cursor: pointer;
}
.payment-view-gap {
  margin-top: 5%;
  margin-bottom: 5%;
}

.ok-img {
  width: 500px !important;
  height: 500px;
  object-fit: fit;
}
.ok-img-qr {
  width: 300px !important;
  height: 300px;
  object-fit: fit;
  margin-top: 25%;
}
.ok-div {
  width: 83vw;
  padding-left: 8.5vw;
}
.contact-us-txt {
  color: #bb7005;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: -7px;
}
.contact-details {
  flex-direction: row;
  display: flex;
}
.contact-details-div {
  width: 54%;
  background-color: #454246;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 7%;
  height: 30%;
  margin-right: 1%;
}
.contact-details-txt {
  color: #fff;
  font-size: 17px;
}
.contact-form {
  margin: 10%;
}
.packageDiv {
  border: 2px solid #d9a054;
}
.innerPackageDivOn {
  border: 2px solid #d9a054;
  border-radius: 17px;
  height: 17px;
  width: 17px;
  margin-left: 30%;
}
.txt-package {
  text-align: center;
}
#modalText {
  font-family: 'Lato', sans-serif;
  color: #d4943f;
  font-weight: bold;
  padding-left: 0.5%;
  margin-right: 1.5%;
}
/* HERE */
.sc-hm-one {
  background-repeat: no-repeat, repeat;
  background-position: right top;
  background-image: url("../../assets/images/RectangleFront.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.sc-hm-one-in {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgb(19, 19, 17, 0.8);
  padding-left: 13px;
  padding-bottom: 3%;
}

.text-pop {
  float: left;
  text-align: left;
}

.home-why-us-title {
  font-family: 'Lato', sans-serif;
  color: #d4943f;
}
p.txt_.Title {
  color: white !important;
  font-family: 'Lato', sans-serif;
  font-size: 44px !important;
  margin-top: 46px !important;
  line-height: 59px;
  font-weight: bold;
}
p.txt-title-modal {
  color: #193f6a;
  font-size: 28px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}
p.txt-title-modal-amt {
  color: #193f6a;
  font-size: 32px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  margin-top: -25px;
}
p.txt-title-modal-amt-months {
  color: #193f6a;
  font-size: 23px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  margin-top: -31px;
}
.home-why-us-title {
  font-family: 'Lato', sans-serif;
  color: white;
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}
p.txt_.Title.Second {
  font-size: 28px !important;
  margin-top: 49px;
  font-family: 'Lato', sans-serif;
  line-height: 35px;
  margin-bottom: 48px;
}
p.title-content-h {
  font-family: 'Lato', sans-serif;
  font-size: 33px;
  color: #d4943f;
  font-weight: bold;
  margin-top: 4px;
}
p.txt_.one {
  color: #193f6a !important;
  font-family: 'Lato', sans-serif;
  font-size: 39px !important;
  font-weight: 600;
  /* font-weight: bold !important; */
  margin-top: 31px;
  margin-left: 8px;
}
p.txt_.two {
  color: #193f6a !important;
  font-family: 'Lato', sans-serif;
  font-size: 19px !important;
  font-weight: 600 !important;
  margin-top: -20px !important;
  margin-left: 13px;
}
p.txt_.three {
  color: #193f6a !important;
  font-family: 'Lato', sans-serif;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 12px !important;
  margin-top: -11px !important;
  margin-left: 14px;
}
p.txt_.subTitle {
  color: white !important;
  font-family: 'Lato', sans-serif;
  font-size: 14px !important;
  line-height: 23px !important;
  margin-top: 15px !important;
  margin-left: 2px !important;
}
p.txt_.one {
  margin-top: 56px !important;
}
p.txt_.subTitle.Second.p {
  margin-top: 34px !important;
}

p.txt_.Title.Second {
  font-size: 28px !important;
  margin-top: 49px;
  font-family: 'Lato', sans-serif;
  line-height: 35px;
  margin-bottom: 48px;
}
button.visit-site {
  width: 140px;
  height: 40px;
  border-radius: 20px;

  color: white;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  background: radial-gradient(
    214.91% 214.91% at 56.12% 50.88%,
    #d3ad40 0%,
    rgba(211, 173, 64, 0) 100%
  );
  filter: drop-shadow(5px 10px 7px rgba(255, 243, 243, 0.25));
  border-radius: 45px;
  margin-bottom: 17px;
  margin-top: 30px;
}
.aln-hm-cn {
  float: right;
  margin-top: 22px;
}
.aln-itms-cntr.thrr {
  text-align: center;
  margin-top: -25px;
  margin-left: 92px;
}
/* about us*/

.container-cmmon {
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 42px;
}
@media only screen and (max-width: 999px) and (min-width: 698px) {
  .container-cmmon {
    padding-left: 45px;
    padding-right: 45px;
  }

  .home-abt {
    padding-left: 45px;
    padding-right: 45px;
  }
}
@media only screen and (min-width: 1000px) {
  .container-cmmon {
    padding-left: 55px;
    padding-right: 55px;
  }
  .home-abt {
    padding-left: 55px;
    padding-right: 55px;
  }
}

@media only screen and (min-width: 1700px) {
  .container-cmmon {
    padding-left: 70px;
    padding-right: 70px;
  }
  .home-abt {
    padding-left: 70px;
    padding-right: 70px;
  }
}
img.home-btn-img {
  margin-top: -20px;
  display: block;
  height: 12px;
  margin-left: 15px;
  margin-bottom: 10px;
}
.cont-text.ft-ct {
  margin-top: 56px;
}
@media only screen and (max-width: 612px) {
  p.txt_.one {
    margin-top: 56px !important;
  }
  .cont-text.ft-ct {
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px !important;
    padding-right: 10px !important;
  }
}
@media only screen and (max-width: 667px) {
  .container-cmmon {
    margin-left: 50px;
    margin-right: 50px;
  }

  #packageSelect {
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .text-con {
    text-align: center;
    padding-top: 10px;
  }
  #packageSelect1 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .packageSelect1 {
    padding-left: 20px;
    padding-right: 20px;
  }
  #packageSelect2 {
    margin-left: 20px;
    margin-right: 20px;
    width: 89%;
  }
  .slide_Imgs {
    height: 50%;
  }
  p#marginInputs {
    margin-left: 7%;
  }
  p.choosePayment {
    margin-left: 20px;
    margin-top: 30px;
  }
  #paymentView_change {
    padding-left: 20px;
    padding-right: 20px;
  }
  .colOrderView.col {
    margin-left: 80px;
    margin-right: 80px;
  }

  div.MuiFormControl-root {
    width: 89%;
    margin-left: 20px;
    margin-right: 20px;
  }
}
.cont-text.ft-ct {
  background: #5a564f;
  display: grid;
  padding-top: 28px;
  padding-bottom: 20px;
  padding-left: 20px;
}
@media only screen and (min-width: 900px) {
  img.entr-gr-one {
    margin-right: 20px;
    margin-bottom: -74px;
  }
}

@media only screen and (min-width: 1300px) {
  .hp_contain.Two {
    margin-left: -99px !important;
    margin-top: 24px;
    padding-bottom: 1px;
  }

  .hp_contain.Four {
    margin-left: -99px !important;
    margin-top: 20px;
    padding-bottom: 1px;
  }
}
.home-txt-content.drk {
  font-family: 'Lato', sans-serif;
  color: #777676;
  font-size: 17px;
}
.rdMore {
  padding-bottom: 4px;
  cursor: pointer;
  color: #737373;
  font-weight: 500;
  font-size: 16.6px;
  font-family: 'Lato', sans-serif;
}
.pop-box {
  background-repeat: no-repeat, repeat;
  background-position: right top;
}
@media only screen and (max-width: 850px) {
  .pop-box.top-contain {
    margin-top: 6%;
    width: 60%;
  }

  .contact-contacts {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 1000px) {
  .contact-contacts {
    padding-left: 55px;
    padding-right: 55px;
  }
}
.contact-contacts {
  padding-top: 30px;
}
@media only screen and (max-width: 1091) {
  .pop-box.top-contain {
    margin-top: 22%;
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 900px) {
  .pop-box.top-contain {
    margin-top: 100px !important;
  }
}
.pop-box.top-contain {
  height: 100%;
  /* margin-top: auto; */
  margin-bottom: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 1356px) {
  .pop-box.top-contain {
    margin-left: 121px;
  }
}
.pop-box.top-contain {
  margin-top: 57px !important;
  text-align: center;
}
.hp_contain {
  background: #444346;
  border-radius: 34px;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 5px;
  padding-top: 17px;
  padding-bottom: 24px;
}
.hp_contain.Two {
  margin-left: -50px;
  margin-top: 24px;
  padding-bottom: 1px;
  margin-top: 22px;
}
.hp_contain.Four {
  margin-left: -50px;
  margin-top: 20px;
  padding-bottom: 1px;
}
img.entr-gr-two {
  margin-top: 12px;
  height: 350px;
}
@media only screen and (min-width: 700px) {
  img.entr-gr-one {
    margin-top: 78px;
    margin-left: 40px;
    margin-right: 20px;
    padding-left: 20px;
    padding-right: 40px;
  }
}
img.entr-gr-one {
  margin-right: 20px;
  height: 250px;
}
img.entr-gr-three {
  height: 158px;
  margin-left: 60%;
}
p.home-txt-content.tx {
  color: #fff8f8 !important;
  font-size: 15px;
  font-family: 'Lato', sans-serif;
  line-height: 30px;
}
p.home-txt-content {
  font-family: 'Lato', sans-serif;
  text-align: left;
  margin-top: -7px;
  color: white;
}
.slide_Imgs {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.hr-in-vrtl {
  margin-top: 30%;
}

.colOrderView.col {
  margin: 10px;
}
img.roundImageTop {
  margin: 1%;
  max-width: 100%;
  max-width: 100%;
}
.check-icon {
  height: 80px;
  width: 80px;
  margin-top: 3%;
}
p.orderComplete-txt {
  color: #5a564f !important;
  font-size: 25px;
  font-family: 'Lato', sans-serif;
  line-height: 30px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}
p.orderDetails-header {
  color: #5a564f !important;
  font-size: 22px;
  font-family: 'Lato', sans-serif;
  line-height: 30px;
  margin-top: 15%;
  font-weight: 600;
}
p.orderDetails-lhs {
  color: #5a564f !important;
  font-size: 16.5px;
  font-family: 'Lato', sans-serif;
  line-height: 17px;
  font-weight: 600;
}
p.orderDetails-rhs {
  color: #5a564f !important;
  font-size: 16.5px;
  font-family: 'Lato', sans-serif;
  line-height: 17px;
  text-align: right;
  margin-right: 15px;
}
