@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.notif-active{
    color: #00AEEC;
}
.notification-div:hover{
    border: 1.5px solid #00AEEC;
}
.notif-margin{
    margin-bottom: 5%;
}
@media screen and (min-width: 600px) {
    .notification-div{
        padding-top: 1.2%;
        padding-bottom: 1.2%;
        padding-left: 3%;
        padding-right: 3%;
        border: 2px solid #eeeeee;
        box-shadow:0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
        border-radius: 5px; 
        margin-bottom: 2%;
        width:90%;
        margin-left: 5%; 
        height:auto;
        cursor: pointer;
    }
    .notification-txt{
        font-size: 16.5px;
        color: #00AEEC !important;
        margin-bottom: 0px;
        text-align: left;
        margin-left: -35px;
    }
    p.text_Title-notif {
        color: #02486c !important;
        font-family: 'Lato', sans-serif;
        font-weight: 600;
        font-size: 26px !important;
      }
}
@media screen and (max-width: 599px) {
    .notification-div{
        padding-top: 1.2%;
        padding-bottom: 1.2%;
        padding-left: 3%;
        padding-right: 3%;
        border: 2px solid #eeeeee;
        box-shadow:0 5px 10px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
        border-radius: 5px; 
        margin-bottom: 5%;
        word-wrap: break-word;
        width:100%;
        height:auto;
        cursor: pointer;
    }
    .notification-txt{
        font-size: 16.5px;
        color: #00AEEC !important;
        margin-bottom: 0px;
        text-align: left;
    }
    .notif-active{
        margin-top: 7px;
    }
    p.text_Title-notif {
        color: #02486c !important;
        font-size: 24px !important;
        margin-top: 18%;
        font-family: 'Lato', sans-serif;
        font-weight: 600;
      }
}