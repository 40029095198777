@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
p.txt_.Paragraph {
  color: #193f6a !important;
  font-family: 'Lato', sans-serif;
  font-size: 16px !important;
  line-height: 23px !important;
  margin-top: 19px !important;
  margin-left: 2px !important;
}
.markerTxt{
  color: #193f6a !important;
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
}
iframe.adVideo {
  width: 100%;
  height: 300px;
  border-radius: 15px;
}
.shareListingHeader{
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  color: #222;
  font-size: 20px;
}
iframe.adVideo-mobile {
  width: 100%;
  height: 260px;
  border-radius: 15px;
}
.ads-img-slider {
  height: 300px;
  width: 100%;
  object-fit: fill;
}
.ads-img-slider-div{
  border-radius: 15px !important;
  background-color: #113c7c;
  overflow: hidden;
}
.reduce-padding {
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.ads-call-icons {
  color: #113c7c;
  cursor: pointer;
  z-index: 999999999999;
}
.ads-img-slider-mobile {
  height: 250px;
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-bottom: 5% !important;
}
.ads-searchBar {
  width: 90%;
  border-radius: 5px;
  padding-left: 19px !important;
  border: 1.9px solid #bfbfbf;
  padding-top: 5px;
  min-height: 38px;
}

.brocher-btn-virtual-mobile {
  border-radius: 7px;
  background-color: #00AEEC;
  border: 1px solid #fff;
  margin-top: 3%;
  width: 140px;
  height: 40px;
  margin-right: 35px;
}
.ads-img-icons {
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.new-Ads-icons {
  width: 60%;
  margin-top: 5%;
}
.overView_highlight_active{
  border-bottom: 2px solid #00476b;
  float: left;
  cursor: pointer;
}
.overView_highlight{
  float: left;
  cursor: pointer;
}
p.overView_highlight_txt{
  margin-bottom: 3px;
}
p.overView_highlight_txt_active{
  margin-bottom: 3px;
  font-weight: 600;
  color: #00476b;
}
.new-banner {
  display: flex;
  width: 100%;
  border-radius: 0px;
  justify-content: flex-end;
  padding-top: 0px;
  position: relative;
  margin-left: -1%;
}
.ads-vt-icon {
  height: 50px;
  width: 180px;
  margin-left: 40px;
}
.border-class {
  border-right: 2px solid #a0a0a038;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
}
.ads-info-div {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-left: 5%;
  margin-left: 5%;
}
.border-class_2 {
  height: 55px;
  margin-top: 15px;
}
.ads_info_icon{
  height: 30px;
  object-fit: contain;
}
span.pp1Txt {
  color: rgb(101, 101, 101);
  font-size: 15px;
  font-weight: 500;
}
.brocher-btn-virtual:hover{
  background-color: #00476b;
}
p.pp1Txt_gray {
  color: rgb(150, 150, 150);
  font-size: 14px;
  margin-top: -7px;
  text-align: center;
  font-weight: 600;
}
p.pp1Txt_mobile {
  color: Black;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}
p.pp1Txt_mobile2 {
  color: Black;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  margin-top: 3px;
}
#type-style {
  margin-top: 15%;
  margin-left: 13%;
}
#price-style {
  padding-top: 5%;
}
p.pptxt-pp1 {
  color: #113c7c;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  word-wrap: break-word;
  font-family: 'Lato', sans-serif;
}
p.pptxt-pp1-mobile {
  color: #113c7c;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  word-wrap: break-word;
  text-align: center;
  font-family: 'Lato', sans-serif;
}
p.propadd-txt {
  color: #113c7c;
  font-size: 14px;
  font-weight: 500;
  margin-top: 7%;
  text-align: center;
  margin-bottom: 5px;
}
p.landmark-txt {
  color: gray;
  font-size: 15px;
  margin-top: 3%;
  text-align: center;
  font-weight: 500;
}
.landmark-icon {
  color: gray;
  margin-right: 5px;
}
#add_address {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Lato', sans-serif;
  color: #113c7c;
  margin-left: 8px;
  text-align: left;
}
#add_landmark {
  font-weight: 700;
  font-size: 14px;
  margin-left: 7px;
  text-align: left;
}
p.ad-title {
  color: #222;
  font-style: normal;
  font-size: 18px;
  margin-bottom: 18px;
  cursor: pointer;
  font-weight: 600;
}
p.ad-title:hover{
  color: rgb(99, 99, 99);
}
p.ads-active-txt {
  color: #28943b;
  margin-right: 100px;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
}

p.ads-active-txt1 {
  color: #28943b;
  font-weight: bolder;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  align-items: center;
  margin-top: 6%;
}

p.ad-title-mobile {
  color: gray;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  margin-top: 5%;
}
p.text_Title-ads {
  color: #02486c !important;
  font-size: 24px !important;
}
.column1 {
  background-color: #29292acc;
  padding: 4%;
}
.cards-ads {
  background-color: #fff;
  padding: 3%;
}
.cards-box-ads {
  border-radius: 12px;
  background-color: #fff;
  width: 92%;
  height: 40%;
  margin-left: 4%;
  padding-top: 2%;
  padding-left: 2.5%;
  padding-right: 2%;
  padding-bottom: 1%;
  border-top: 0.5px solid rgba(77, 166, 255);
  box-shadow: rgba(77, 166, 255) 0px 2px 5px -1px, rgba(77, 166, 255, 0.5) 0px 1px 3px -1px;
}
.cards-box-ads-mobile {
  box-shadow: rgba(77, 166, 255, 1) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 20px;
  background-color: #fff;
  width: 95%;
  margin-left: 2.5%;
  padding-top: 2%;
  padding-left: 4% !important;
  padding-right: 4% !important;
  padding-bottom: 5% !important;
}
.text_subHeader {
  margin-top: 3%;
  font-size: 17px;
}
.text_subTitle {
  margin-top: 5%;
  padding-bottom: 8%;
  margin-bottom: 4%;
  font-size: 17px;
}

.container-ads {
  width: 100vw;
}
.boxes-3-margin{
  margin-top: 10px;
}

@media only screen and (max-width: 999px) and (min-width: 698px) {
  .new-bx-ads {
    padding-left: 25px;
    padding-right: 20px;
  } 
}
.adId-mobile {
  color: gray;
}
@media only screen and (max-width: 860px) {
  .new-bx-ads {
    padding-left: 10px;
    padding-right: 10px;
  }
  .verified_icon{
    height: 30px;
    object-fit: contain;
    margin-top: 8%;
    float: right;
  }
  #leftCol2 {
    border-top-right-radius: 15px;
    transform: translate(10px);
    margin-right: 6%;
  }
}
.container-ads {
  margin-top: 40px;
}

@media only screen and (min-width: 1000px) {
  .new-bx-ads {
    padding-left: 25px;
    padding-right: 20px;
  }
}
.new-buy {
  margin-left: 60px;
  color: grey;
}
.new-site {
  margin-left: 35px;
  font-size: 35px;
  font-weight: 500;
  color: #00476b;
  font-family: 'Lato', sans-serif;
}

@media only screen and (min-width: 1700px) {
  .new-bx-ads {
    padding-left: 25px;
    padding-right: 20px;
  }
}
@media only screen and (min-width: 861px) {
  .cards-box-ads-mobile {
    display: none;
  }
  .verified_icon{
    height: 35px;
    object-fit: contain;
  }
  #leftCol2 {
    border-top-right-radius: 15px;
    transform: translate(10px);
    margin-right: 4.7%;
  }
  .card-ads-topDiv{
    margin-bottom: 1%;
  }
  .cards-box-ads {
    display: block;
  }
  .pagination-forPC {
    display: block;
  }
  .pagination-forMobile {
    display: none;
  }
  .new-bx-ads {
    align-self: center;
    width: 90%;
    padding-left: 20px;
    padding-right: 80px;
    border-radius: 30px;
    border: 1 px solid black;
    margin-left: 60px;
    margin-right: 40px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 5px;
  }
  .margin-pagination {
    display: flex;
    justify-content: center;
  }
}
p.ads-active-txt-mobile {
  color: #28943b;
  float: right;
  text-align: right;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  margin-top: 12% !important;
}
@media screen and (max-width: 860px) {
  .cards-box-ads-mobile {
    display: block;
  }
  .pagination-forPC {
    display: none;
  }
  .pagination-forMobile {
    display: block;
  }
  .margin-pagination {
    display: flex;
    justify-content: center;
  }
  .info-mobile {
    margin-top: 6%;
    background: #f7f7f7;
    margin-left: 2% !important;
    margin-right: 2% !important;
    padding-top: 3% !important;
  }
  .border-class {
    border-right: 1.5px solid #a0a0a038;
    margin-top: 5px;
  }
  .cards-box-ads {
    display: none;
  }
  .icons-div {
    display: inline;
    max-height: 35px;
    margin-bottom: 20px;
  }
  .btns-div {
    display: inline-flex;
    margin-top: 5%;
  }
  .ad-id-txt {
    margin-top: 10px;
  }
  .ads-img-icons-mobile {
    width: 20px;
    cursor: pointer;
    float: right;
    margin-right: 8px;
  }
}

.custom-item {
  background-color: #fff;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  cursor: pointer;
  background-color: #fff;
  color: #02486c;
}

.custom-item--active {
  background-color: #00AEEC;
  border-radius: 50px;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.custom-item--disable {
  opacity: 0.5;
}
.custom-root ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.custom-go-item {
  align-items: center;
  display: flex;
  font-size: 1rem;
  padding-inline-end: 0.75rem;
  padding-inline-start: 0.75rem;
  width: 150px;
}
.custom-go-item input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 40px;
  width: 100%;
}
.custom-progress-bar {
  background-color: #9e75ff;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(174, 0, 255, 0.4);
  height: 3px;
  margin: 1rem 0;
  max-width: 100%;
  transition: width 0.2s ease;
}
.main-navbar {
  display: flex;
}

#searchButton {
  background-color: #00AEEC;
  border-radius: 45px;
  width: 120%;
  margin-top: 20%;
}
.search-col {
  border-radius: 45%;
  width: 85%;
}
.selectCity {
  width: 100%;
  border: none;
  margin-bottom: 5px;
}
.select-padding {
  padding-right: 1%;
}

#selectPropertyType {
  width: 10% !important;
}

.main-row {
  margin-left: 2%;
}
.main-column {
  display: flex;
}
.detail-btn {
  padding-left: 30px;
}
#renderdata-alignment {
  justify-content: center;
}
