@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
p.txt_.Paragraph {
    color: #193f6a !important;
    font-family: 'Lato', sans-serif;
    font-size: 16px !important;
    line-height: 23px !important;
    margin-top: 19px !important;
    margin-left: 2px !important;
  }
  
  p.text_Title {
    color: #193f6a !important;
    font-size: 47px !important;
  }
  .new-bx{
    padding-left: 2%;
    padding-right: 2%;
  }
  .text_subHeader {
    margin-top: 3%;
    font-size: 17px;
  }
  .text_subTitle {
    margin-top: 5%;
    padding-bottom: 8%;
    margin-bottom: 4%;
    font-size: 17px;
  }
  
  .container {
    background-repeat: no-repeat, repeat;
    background-position: right bottom;
    background-attachment: fixed;
  }
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  @media only screen and (max-width: 999px) and (min-width: 698px) {
    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .container {
    margin-top: 20px;
  }
  @media only screen and (min-width: 1000px) {
    .container {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
  
  @media only screen and (min-width: 1700px) {
    .container {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  