.bg-last-sec{

    /* background: url(./Rectangle\ 105\ \(1\).png); */
    /* background: linear-gradient(100.3deg, rgba(255, 239, 162, 0.979) 37.21%, rgba(0, 173, 236, 0.793) 78.06 ) ; */
background-size: cover;
background-repeat: no-repeat;
}
.ratio-16x9 {
    width: 70% !important;
}


.small-video-card{
    width: 132px;
height: 87px;
 

}
.heading{
    >h1{
        font-family: Manrope;
font-size: 20px;
font-weight: 800;
line-height: 35px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: rgba(9, 8, 8, 1);
    }
    p{
        font-family: Manrope;
font-size: 16px;
font-weight: 400;
line-height: 35px;
text-align: left;
text-underline-position: from-font;
 

text-decoration-skip-ink: none;
color: black;
    }
}