@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
.services-top-div {
  float: left;
  text-align: left;
  margin-top: 8%;
}
.services-container {
  padding-bottom: 5%;
}
p.services-Paragraph {
  color: #193f6a !important;
  font-family: 'Lato', sans-serif;
  font-size: 16px !important;
  line-height: 23px !important;
  margin-top: 19px !important;
  margin-left: 2px !important;
}
.services-img-slider {
  height: 250px;
  width: 100%;
  border-radius: 10px;
}
@media only screen and (max-width: 766px) {
  p.services-top-title {
    font-size: 32px !important;
    word-wrap: break-word;
  }
  .services-container {
    width: 90%;
    margin-left: 5%;
  }
  .services-img-slider {
    margin-top: 4%;
  }
}
@media only screen and (max-width: 999px) and (min-width: 698px) {
  .services-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 1000px) {
  .services-container {
    padding-left: 7%;
    padding-right: 7%;
  }
}

@media only screen and (min-width: 1700px) {
  .services-container {
    padding-left: 9%;
    padding-right: 9%;
  }
}
@media only screen and (min-width: 766px) {
  .services-img-slider {
    margin-top: 15%;
  }
}
.services-div-margins {
  margin-top: 2%;
}
p.services-top-title {
  color: #193f6a !important;
  font-family: 'Lato', sans-serif;
  font-size: 47px !important;
}
button.services-btn {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  background: #00AEEC;
  filter: drop-shadow(5px 10px 7px rgba(255, 243, 243, 0.25));
  border-radius: 45px;
  margin-bottom: 17px;
  margin-top: 10px;
}
