@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
 .firstDiv{
      padding: 5%;
      justify-content: center;
      align-items: center;
      align-self: center;
      display: flex;
  }
  .secondDiv{
    padding-left: 9%;
    padding-right: 9%;
}
  
  .registerInputMargin{
      margin-bottom: 2%;
  }
  .registerDailDiv{
    margin-left: 0px;
  }
  
  p.subRegisterTitle {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    letter-spacing: 0.01em;
    margin-top: -3px;
    color: #02486c;
  }
  .verifyRegister-btn{
    font-family: 'Lato', sans-serif;
    background: #00AEEC;
    font-weight: 600;
    height: 45px;
    width: 150px;
    border-radius: 5px;
    margin-bottom: 2%;
    margin-top: 5%;
  }
  .verifyRegister-btn:hover{
    background-color: #02486c;
  }
  @media only screen and (min-width: 600px) {
    .registrationContainer{
      margin-left: 7%;
      margin-right: 7%;
      margin-bottom: 5%;
    }
    .secondDiv_verify{
      padding-left: 15%;
    }
    input.registerInput{
      border-radius: 5px;
      padding-left: 10px;
      width: 100%;
      border: 1px solid #bfbfbf;
      min-height: 45px;
      margin-bottom: 15px;
      margin-left: 8px;
    }
    p.register_subtxt{
      font-size: 15px;
      font-weight: 600;
      color: #000;
      margin-top: 7%;
      margin-left: 8px;
      font-family: 'Lato', sans-serif;
    }
    p.register_subtxt2{
      font-size: 15px;
      font-weight: 600;
      color: #000;
      font-family: 'Lato', sans-serif;
      margin-left: 8px;
    }
    p.register_txt{
      font-family: 'Lato', sans-serif;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: left;
      font-size: 34px !important;
      font-weight: 600;
      /* font-weight: bold !important; */
      margin-top: 31px;
      margin-left: 8px;
      color: #000;
    }
    .register_hr{
      border-bottom: 5px solid #00AEEC ;
      width: 52px !important;
      border-radius: 100px;
      margin-left: 8px;
    }
    .firstDiv-img{
      object-fit: contain;
      width: 100%;
      justify-content: center;
      align-items: center;
      align-self: center;
      display: flex;
  }
  }
  @media only screen and (max-width: 599px) {
    .registrationContainer{
      margin-left: 1%;
      margin-right: 1%;
    }
    .secondDiv_verify{
      padding-left: 5%;
      padding-right: 5%;
    }
    input.registerInput{
      border-radius: 5px;
      padding-left: 10px;
      width: 100%;
      border: 1px solid #bfbfbf;
      min-height: 45px;
      margin-bottom: 15px;
    }
    p.register_subtxt{
      font-size: 15px;
      font-weight: 600;
      color: #000;
      margin-top: 7%;
      font-family: 'Lato', sans-serif;
    }
    p.register_subtxt2{
      font-size: 15px;
      font-weight: 600;
      color: #000;
      font-family: 'Lato', sans-serif;
    }
    p.register_txt{
      font-family: 'Lato', sans-serif;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: left;
      font-size: 34px !important;
      font-weight: 600;
      /* font-weight: bold !important; */
      margin-top: 31px;
      color: #000;
    }
    .register_hr{
      border-bottom: 5px solid #00AEEC ;
      width: 52px !important;
      border-radius: 100px;
    }
    .firstDiv-img{
      object-fit: contain;
      width: 80%;
      margin-top: 10%;
      margin-bottom: 5%;
      justify-content: center;
      align-items: center;
      align-self: center;
      display: flex;
  }
}
span.register_btn-txt{
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 15px;
}
input.registerInput:focus{
  border: 2px solid #00AEEC;
}
span.errorText_register{
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  letter-spacing: 0.01em;
  color: red;
  margin-top: -5px;
  margin-left: 7px;
  margin-bottom: 20px;
}
p.almostThere_txt{
  color: #02486c;
  font-weight: 600;
  font-size: 42px;
  padding-top: 9%;
  font-family: 'Lato', sans-serif;
}