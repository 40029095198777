@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

@media only screen and (max-width: 1200px) {
  #searchButton-home {
    border-radius: 35px;
    width: 100%;
  }
  #searchButton-home_searchCard {
    border-radius: 35px;
    width: 65%;
  }
  #searchButton-home-white {
    border-radius: 45px;
    width: 100%;
  }
  .textField-new::placeholder {
    color: black;
    opacity: 1;
  }
  p.smartVisit-title {
    padding-top: 4%;
    font-size: 30px;
    margin-left: 8%;
    color: #00476b;
    font-weight:bolder;
   font-family: 'Lato', sans-serif;
  }
  p.buy_txt{
    display: none;
  }
  .new-site {
    margin-left: 20px;
  }
  .new-bx-ads-homestyle {
    display: none !important;
  }
  .new-bx-ads-homestyle-mobile {
    display: block;
    background-color: #fff;
    border: 1.2px solid #aeaeae !important;
    border-radius: 60px;
    width: 100%;
    
  }
  .filterIcon {
    background-color: #00AEEC;
    padding: 4.5px;
    color: #fff;
    border-radius: 7px;
    float: right;
    margin-right: 15px;
  }
  .textField-new {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    border: none;
    border-color: transparent;
    background-color: transparent;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1100px) {
  button.brocher-btn {
    border-radius: 7px;
    background-color: #030363;
    border: none;
    color: white;
    padding: 5px 10px;
    height: 50px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }
  
  .brocher-btn-white {
    border-radius: 7px;
    background-color: #00AEEC;
    margin-top: 3%;
  }
  .brocher-btn-virtual {
    border-radius: 5px;
    background-color: #00AEEC;
    border: none;
    color: white;
    padding: 2px 10px;
    height: 45px !important;
    width: 90%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 1099px) {
  button.brocher-btn {
    border-radius: 7px;
    background-color: #030363;
    border: none;
    color: white;
    padding: 5px 10px;
    height: 50px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14.5px;
    cursor: pointer;
  }
  
  .brocher-btn-white {
    border-radius: 7px;
    background-color: #00AEEC;
    margin-top: 3%;
  }
  .brocher-btn-virtual {
    border-radius: 7px;
    background-color: #00AEEC;
    border: none;
    color: white;
    padding: 2px 10px;
    height: 50px !important;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
  }
  .brocher-btn-virtual_new {
    border-radius: 7px;
    background-color: #00AEEC;
    border: none;
    color: white;
    padding: 2px 10px;
    height: 45px !important;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    cursor: pointer;
    margin-top: 5px;
  }
  .brocher-btn-virtual_new_blue {
    border-radius: 7px;
    background-color: #030363;
    border: none;
    color: white;
    padding: 2px 10px;
    height: 45px !important;
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    cursor: pointer;
    margin-top: 5px;
  }
}
@media only screen and (min-width: 1201px) {
  .new-bx-ads-homestyle {
    background-color: #fff;
    border: 1.2px solid #aeaeae !important;
    border-radius: 60px;
    width: 80%;
    padding-top: 4.3px;
    padding-bottom: 7.7px;
    display: block !important;
    margin-left: 9%;
  }
  p.smartVisit-title {
    padding-top: 4%;
    font-size: 40px;
    margin-left: 8.7%;
    color: #00476b;
    font-weight:bolder;
   font-family: 'Lato', sans-serif;
  }
  .textField-new::placeholder {
    color: #888888;
    opacity: 1;
    text-align: center;
  }
  .textField-new {
    width: 85%;
    padding-left: 40px;
    margin-right: 15%;
    border: none;
    border-color: transparent;
    background-color: transparent;
    margin-top: 5.8px;
  }
  p.buy_txt{
    color: grey;
    font-size: 20px;
    margin-left: 11.5%;
    display: block;
  }
  .new-bx-ads-homestyle-mobile {
    display: none !important;
  }
  .searchCard-dividerRight {
    border-right: 1.7px solid #00AEEC;
    padding: 0px !important;
    margin: 0px !important;
  }
  .searchCard-dividerRight_First{
    border-right: 1.7px solid #00AEEC;
    width: 95%;
    margin-left: 16%;

  }
  .searchCard-dividerRight-Input {
    border-right: 1.7px solid #00AEEC;
    margin-top: 9px;
    height: 40px;
  }
}
.options-select {
  overflow: ellipsis
}
#searchButton-home {
  background-color: #00AEEC;
  border-radius: 10px;
  height: 40px;
  font-size: 0.95em !important;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-top: 8.3px;
  width: 100% !important;
  padding-top: 5px;
  justify-content: center;
  align-items: center;
  align-self: center;
}
button.searchButton-home_searchCard {
  width: 65% !important;
  height: 37px;
  border-radius: 5px !important;
  color: white;
  font-size: 0.95em !important;
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-left: 5%;
  background-color: #00AEEC;
  filter: drop-shadow(5px 10px 7px rgba(255, 243, 243, 0.25));
  margin-top: 8.3px;
  padding-top: 5px;
  transition-duration: 0.5s;
  margin-right: 17.5%;
  justify-content: center;
  align-items: center;
  align-self: center;
}
button.searchButton-home_searchCard:hover{
  background-color: #193f6a;
  color: #fff !important;
}
#searchButton-home-white {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  color: #00AEEC !important;
  border-color: #00AEEC;
  border-width: 1.5px;
  height: 40px;
  font-size: 0.95em !important;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
}
#searchButton-home:hover {
  color: white !important;
  background-color: #02486c !important;
}
#searchButton-home-white:hover {
  background-color: #02486c !important;
  color: #fff !important;
}
.newSettings {
  margin-top: auto;
  margin-bottom: auto;
}


/* Typography */
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&family=Ubuntu:wght@300;400;700&display=swap');

.row{
  display: flex ;
  flex-wrap: wrap;
  flex-direction: row;
}
.col-4{
  width: 33.33% !important;
}
body {
    margin: 0;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
}
h1, 
h2,
h3 {
    font-family: 'Lora', serif;
    font-weight: 400;
    color: #143774;
    margin-top: 0;
}
h1{
  font-size: 2rem;
  margin: 0;
}
a {
    color: #1792d2;
}

a:hover,
a:focus {
    color: #143774;
}

strong {
    font-weight: 700;
}
.subtitle{
  font-size: 0.85rem;
  font-weight: 700;
  margin: 0;
  color: #1792d2;
  letter-spacing: 0.05em;
  font-family: 'Ubuntu Bold', sans-serif;
}
.article-title {
    font-size: 1.5rem;
}

.article-read-more,
.article-info {
    font-size: .875rem;
}

.article-read-more {
    color: #1792d2;
    text-decoration: none;
    font-weight: 700;
}
.article-read-more:hover,
.article-read-more:focus {
    color: #143774;
    text-decoration: underline;
}
.article-info {
    margin: 2em 0;
}
header{
  padding: 2em 0;
  text-align: center;
  background: #f0f8ff;
  font-family: sans-serif;
  margin-bottom: 3em;
}
.container-flex{
  max-width: 80vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}
nav ul{
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}
nav li{
  margin: 0 1em;
}
nav a{
  text-decoration: none;
  color: #707070;
  font-weight: 700; 
  padding: 0.25em 0;
}

nav a:hover,
nav a:focus{
  color: #143774;
  border-bottom: 1px solid black;
  transition: .3s ease;
}

img{
  max-width: 100%;
  display: block;
}
main{
  max-width: 75%;
}
.article-body{
  width: 100%;
  text-align: justify;
}
.sidebar{
  max-width: 23%;
}
footer {
    background:#143774;
    color: white;
    text-align: center;
    padding: 3em 0;
}
footer a{
  text-decoration: none;
  color: white;
}
footer a:hover{
  text-decoration: underline;
  color: white;
}
@media (max-width:1050px){
  .container-flex{
    flex-direction: column;
  }
  .site-title, .subtitle{
    width: 100%;
  }
  main{
    max-width: 100%;
  }
  .sidebar{
    max-width: 100%;
  }
  
}
@media (max-width: 500px){
  nav ul{
    display: flex;
    flex-direction: column;
  }
  nav li{
    margin: 0.5em 0;
  }
}

/* articles */
.article-featured {
    border-bottom: #707070 1px solid;
    padding-bottom: 2em;
    margin-bottom: 2em;
}
.article-recent {
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
}

.article-recent-main {
    order: 2;
}

.article-recent-secondary {
    order: 1;
}

@media (min-width: 675px) {
    .article-recent {
        flex-direction: row;
        justify-content: space-between;
    }
    
    .article-recent-main {
        width: 68%;
    }
    
    .article-recent-secondary {
        width: 100%;
    }
}

 
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.gallery {
  column-count: 4;
  --webkit-column-count: 4;
  --moz-column-count: 4;
  gap: 1rem;
  padding: 4rem;
}

.image img {
  height: auto;
  width: 100%;
}

/* Responsive-ness for different screen-sizes */
@media screen and (max-width: 810px) {
  .gallery {
    column-count: 3;
    --webkit-column-count: 3;
    --moz-column-count: 3;
  }
}

@media screen and (max-width: 500px) {
  .gallery {
    column-count: 2;
    --webkit-column-count: 2;
    --moz-column-count: 2;
  }
}

@media screen and (max-width: 400px) {
  .gallery {
    column-count: 1;
    --webkit-column-count: 1;
    --moz-column-count: 1;
  }
}

