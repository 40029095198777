@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

@media screen and (min-width: 767px) {  
  
}
@media screen and (max-width: 766px) {
  
}
.cards-box-ads-homepage {
  height: 300px !important;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 95%;
  margin-left: 0.9%;
  margin-right: 4.1%;
}
.ads-img-slider-homestyle {
  width: 100%;
  height: 180px !important;
  object-fit: cover;
}
.ad-title-homepage {
  color: #00476b;
  font-weight: 700;
  padding-left: 7px;
  padding-bottom: 5px;
  font-family: 'Lato', sans-serif;
}
.wrap-searchCard {
  width: 100%;
  padding: 3%;
}
