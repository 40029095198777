.container-wrap{
    padding: 0px 0px;
}


@media (min-width: 1024px) { /* Adjust breakpoint as needed */
  .container-wrap {
      padding: 0px 72px; /* Only for large screens */
  }
}
.SearchSection-main{

        

        background-image: url('../../assets/landing-page/grndcolor.svg'), url('../../assets/landing-page/searchbackg.svg') !important;
        background-size: cover; /* Ensures the image covers the entire element */
        background-position: center; /* Ensures the image is centered */
        background-repeat: no-repeat; /* Prevents the image from repeating */
        align-content: center;
        min-height: 412px;
}

.main-heading{
    font-family: Koh Santepheap;
font-size: 55px;
font-weight: 700;
line-height: 69.82px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #F3E5BF;
}




/* CSS File (SearchHeader.css) */
.bg-custom-dark {
    background-color: #1B4A5A;
    color: white;
  }
  
  
  
  .btn-light {
    background-color: #F5E6D3;
    color: #333;
  }
 
  
  input::placeholder {
    color: #999;
  }

  .drop-btn{
    width: 215px;
    height: 45px;
  color: black !important;
    background-color: #FFFFFF !important;
    justify-content: space-between;
    display: flex;
    border-radius: 5px 0px 0px 5px;
    align-items: center;
  }
  @media (max-width: 500px) { /* Adjust breakpoint as needed */
    .drop-btn{
      width: 100px;
    }
  }


  .upper-tab{

    /* width: 140px !important; */
height: 48px !important;
padding: 0px 23px !important;
 
border-radius: 8px 8px 0px 0px !important;
font-family: Inter !important   ;
font-size: 20px !important;
font-weight: 700 !important;
line-height: 24.2px !important;
text-align: center !important; 
color: #EAD7A9 !important;
background-color: transparent !important;

  }

  .btn-light{
color: #2F4C43 !important;
padding: 0px 23px !important;
    background-color: #F3E5BF !important;
  }

  .search-input{
    width: 642px;
height: 45px;
 
border-radius: 0px 11px 11px 0px;
 
background: #FEFBF1;
box-shadow: 0px 4px 12px 3px #FFF6F640;
}
.search-input::placeholder{
    font-family: Inter;
font-size: 20px;
font-weight: 300;
line-height: 24.2px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

  }

.drop-menu{
    width: 215px;
    color: black !important;
}

.view-Detail{

    width: 117px !important;
height: 37px !important;
background: #304C44 !important;
color: #ffff !important;
border-radius: 4px !important;
 padding: 0px;
margin-top: 0px;
}
.card{
    background: #FFFFFF !important;
    padding: 10px;
    box-shadow: 3px 4px 27px -4px #A39F9F40 !important;


}

.serchDrop{
  width: 100%;
 max-height: 200px;
  background: white;
  position: absolute;
  z-index: 99999;
overflow-y: auto;
  top: 100%;
  border-radius: 5px;
}

.serchDrop>ul{
  padding: 10px;
  list-style: none;
li{
  padding: 10px;
}
li:hover{
  background-color: #B6ACAC33;
}
}
.custom-scroll::-webkit-scrollbar {
  display: none;
}

.custom-scroll {
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  scrollbar-width: none;      /* For Firefox */
}

.sideFIlter{
  flex-direction: row !important;
}

.text-bold-value{
  font-family: inherit;
font-size: 15px;
font-weight: 600;
line-height: 20.4px;
text-align: left;
 color: #3E4958;

}

.card-title-value{
  font-family: inherit;
font-size: 18px;
font-weight: 800;
line-height: 24.48px;
text-align: left;
 color: #3E4958;
 cursor: pointer !important;
 
 
}
@media (max-width: 500px) { /* Adjust breakpoint as needed */
  .card-body>div>.card-text{
    font-size: 15px;
  }
  .text-bold-value{
    
  }
}
.card-title-value:hover{

  text-decoration: underline;
}
.card-title{
  cursor: pointer !important;
}
.card-title:hover{
  text-decoration: underline;

}