@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
.img1{
    width: 100%;
    border-radius: 10px;
    z-index: 1;
}
.img2{
    width:20%;
    float: left;
    border-radius: 25px;
}
.container-details-inner{
    margin-top: 7%;
    position: relative;
}
.img3{
    width:38px;
    height:38px;
    padding: 10px;
    top:7%;
    right: 5%;
    position: absolute;
    background-color: white;
    z-index: 2;
    border-radius: 10px !important;
    cursor: pointer !important;
}
.back-icon{
    width:38px;
    height:38px;
    cursor: pointer;
}
.similarPostImages{
    width: 100%;
    object-fit: contain;
}

.w1{
    width:100%;
}
.w2{
    display: flex;
    justify-content: space-between;
}
.w3{
    margin-left: 11px;
    color: rgb(118,118,118);
    font-family: MuseoSans,Helvetica;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;

}
.w5{
    display: flex;
}
.w4{
    margin-left: 11px;
      font-size: 14px! important;
}
.fix{
    position:fixed; 
    margin-left: 48%;
    
    
}

.desc{
    margin-top: 0px;
    margin-bottom: 4px;
    font-family:MuseoSans,Helvetica;
    color: rgb(17, 17, 17);
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}
.pl3{
    text-align: left;
    margin-bottom: -3px;
}



.div1{
    background-color: rgb(249, 248, 254);
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    margin-bottom: 20px;
    padding-left: 20px !important;
    padding-right: 25px !important;
    width: 100%;
    border-radius: 20px;
}
.check1{
    background-color: #00AEEC !important;
    color: white;
    font-size: 16.5px;
    filter: drop-shadow(5px 10px 7px rgba(255 ,243 ,243,0.25));
    width: 140px;
    height:40px;
    transition-duration: 0.5s;
}
.check1:hover{
    background-color: #193f6a;
}

.check2{
    border-radius: 45px;
}
.r2{
    display: flex;
    justify-content: center;
}
.p2{
    color: black;
    font-size: 300;
}
.style{
    margin-top: 30px;
    margin-bottom: 32px;
    word-break: break-word;
    color: rgb(17, 17, 17);
    font-family: MuseoSans, Helvetica;
    font-size: 20px;
    font-weight: 200;
    line-height: 40px;
}

.title1{
    color: rgb(17, 17, 17);
    margin-top: 10px;
    margin-bottom: 32px;
}
.style1{
    font-size: 27px;
    font-weight: 600;
    word-break: break-word;
    text-align: center;
}
.desc1{
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
}
.trans1{
    margin-top: 0;
    margin-bottom: 16px;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    color: rgb(118,118,118);
}
.para1{
    word-break: break-word;
    white-space: pre-line;
    margin-bottom: 16px;
    margin-top: 10px;
    color: rgb(17,17,17);
    font-size: 16px;
    font-weight: 400;
}

.para1-readMore{
    word-break: break-word;
    white-space: pre-line;
    margin-bottom: 30px;
    color: #00AEEC;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
}
.para1-readMore:hover{
    color: #193f6a;
}
.container { 
    position : relative; 
}
.container c1{
    position: relative;
    display:flex;
}
.d1{
    display: flex; 
}
#r5{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.cards-about-team {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.card-about-team {
    background: #fff;
    height: 14em;
    margin-top: 10px !important;
    margin-bottom: 10%;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    transition: all ease 200ms;
  }
  .card-about-team:hover {
    transform: scale(1.07);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}
  .card-about-team img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    margin-left: 1px;
}

  
.card-about-team h2 {
    color: #fff;
    margin-top: 0.5em;
    line-height: 1.4;
    font-size: 1.1em;
    margin-bottom: -1px;
    margin-left: 1px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    transition: all ease-in 100ms;
    text-align: left;
}
  .card-about-team p {
    color: #fff;
    text-align: left;
    font-size: 13.5px;
    margin-left: 1px;
}
.card-body-about-team {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1em;
    border-radius: 8px;
    background: #fff;
}
.team-imgs{
    height: 110px!important;
    padding: 25px !important;
    object-fit: cover;
    width: 110px !important;
}
p.team-text-title{
    color: black;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
}

p.team-text-subtitle{
    color: rgb(99, 99, 99);
    font-size: 13px;
    margin-top: -7px;
    font-weight: 600;
    text-align: center;
}
.loc1{
    margin-top: 10px;
    margin-bottom: 15px;
    color: rgb(17,17,17);
    font-weight: 500;
    font-size: 24px;
}
.loc1-similar{
    margin-top: 30px;
    margin-bottom: 5px;
    color: rgb(17,17,17);
    font-weight: 500;
    font-size: 24px;
}
.loc2{
    margin-top: 13px;
    margin-bottom: 12px;
    color: rgb(118,118,118);
    font-family: 'MuseoSans Helvetica' ;
    font-size: 18px;
    font-weight: 500; 
    text-align: right;
}

.loc3{
    justify-content: space-between;
}
.r3{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.iframe1{
    border-radius: 50%;
}
.col1{
    display: flex;
    justify-content: flex-start;
}
.audio2{
    text-align: left;
}
.audio3{
    text-align: left;
    font-size: 15px;
    font-weight: 200;
}
.btn1{
    background-color: rgb(245,245,245);
    border: 0px;
    color:black;
}

.r3{
    display: flex;
    
}
.col2{
    display: flex;
    justify-content: center;
}
.row4{
    margin-left: 10px;
    margin-bottom: 10%;
}
.sliderDetailsMargin{
    margin-top: 0px !important;
}
@media only screen and (max-width: 767px) {
    .row-item{
        display: none;
    }
    hr{
        width: 90vw;
    }
    .row-item-mobile{
        display: block;
        width: 100%;
    }
    .hideForMobile{
        display: none !important;
    }
    .back1{
        display: flex;
        justify-content: space-between;
        margin-bottom: -20px !important;
        margin-left: 15px !important;
        
    }
  }
  @media only screen and (min-width: 768px) {
    .row-item{
        position: fixed;
        width: 30%;
        margin-left: 3%;
        margin-top: 4%;
        display: block;
    }
    .back1{
        display: flex;
        justify-content: space-between;
    }
    .row-item-mobile{
        display: none;
    }
    .minHeightForHover{
        margin-bottom: 5%;
    }
    hr{
        width: 47vw;
    }
    .fixedDiv{
        position: absolute;
        width: 100vw;
        height: 2vh;
    }
  }
  

.space{
    width: 35px;
    height: auto;
    display:inline-block
}
.check4{
    height:20px;
    width: 60px;
}
.p3{
    width: fit-content;
}
.pic{
    border-radius: 15px;
}
.d2{
    border-radius: 15px;
    margin: 10PX;
}
.p3{
    text-align: center;
    font-size: 10;
    font-weight: bold;
    justify-content: space-around;
}
.col1{
    display: flex;
}
.r4{
    display: grid;
}
.f1{
    font-size: 24px;
    font-size: 800;
}
.f2{
    font-size: 16px;
    font-weight: 500;
}
.n1{
    font-size: 30px;
    font-weight: 700;
    
}
.box-card{
    background-color: white;
    padding: 7px;
    height: 100px;
    margin-bottom: 15px;
    width: 100%;
    border: 1px solid rgb(238,238,238);
    border-radius: 8px;
}
.text-subtitle{
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    color: rgb(126, 126, 126);
}
.text-titleX{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: rgb(0, 0, 0);
}
.c1{
    background-color: white;
    border: 1px solid rgb(238,238,238);
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 18px;
    border-radius: 8px;
    height: 90px;
    width: 100px;
    margin-left:12px;
    justify-content: center;
}
.c3{
    color: rgb(118,118,118);
    font-size: 16px;
    font-weight: 500;

}
.c4{
    height: 80px;
    width: 100px;
}
.l1{
    font-size: 28px;
    font-weight: 500;
}
.fixed-cards{
    position: fixed;
}


*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


.container-x {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-block: 2rem;
  gap: 2rem;
  margin-right: 20px;
  cursor: pointer;
  margin-top: 20px !important;
  min-height: 280px !important;
}

.card {
    display: flex;
    flex-direction: column;
    width: clamp(20rem, calc(20rem + 2vw), 22rem);
    overflow: hidden;
    box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    background: #ECE9E6;
  background: linear-gradient(to right, #FFFFFF, #ECE9E6);
  
  }
  
  
  
  .card__body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  
  
  .tag {
    align-self: flex-start;
    padding: .25em .75em;
    border-radius: 1em;
    font-size: .75rem;
  }
  
  .tag + .tag {
    margin-left: .5em;
  }
  
  .tag-blue {
    background: #56CCF2;
  background: linear-gradient(to bottom, #2F80ED, #56CCF2);
    color: #fafafa;
  }
  
  .tag-brown {
    background: #D1913C;
  background: linear-gradient(to bottom, #FFD194, #D1913C);
    color: #fafafa;
  }
  
  .tag-red {
    background: #cb2d3e;
  background: linear-gradient(to bottom, #ef473a, #cb2d3e);
    color: #fafafa;
  }
  
  .card__body h4 {
    font-size: 1.5rem;
    text-transform: capitalize;
  }
  
  .card__footer {
    display: flex;
    padding: 1rem;
    margin-top: auto;
  }
  
  .user {
    display: flex;
    gap: .5rem;
  }
  
  .user__image {
    border-radius: 50%;
  }
  
  .user__info > small {
    color: #666;
  }
  .card__image {
    max-width: 100%;
    display: block;
    object-fit: cover;
  }
