@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
.container-cmmon {
  padding-top: 30px;
  padding-left: 21px;
  padding-right: 21px;
}

@media only screen and (max-width: 999px) and (min-width: 698px) {
  .container-cmmon {
    padding-left: 45px;
    padding-right: 45px;
  }
}
p.loginModal_txt{
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #3f3a3a;
  margin-top: 9%;
}
label.login_txt{
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-top: 2px;
  letter-spacing: 0.01em;
  color: #3f3a3a;
  margin-left: -15px;
}
@media only screen and (max-width: 767px) {
  .bgImage{
      width:99%;
      margin-top: 1%;
      margin-bottom: 4%;
  }
  .content-box{
    margin-top: 4%;
  }
  .container-ct-frm-drk {
    padding-bottom: 10px;
    background: white;
    mix-blend-mode: normal;
    box-sizing: border-box;
    box-shadow: 0px 18px 26px rgb(74 63 63 / 25%);
    border-radius: 25px;
    padding: 10px;
    margin-bottom: 10%;
    margin-top: 5%;
  }
  .content-box{
    margin: 5%;
  }
  p.ct-frm-lbl {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #3f3a3a;
  }
  .form-contact {
    margin-top: 4%;
    margin-left: 4%;
    margin-right: 4%;
  }
}
p.ct-frm-lbl_NewTexrt {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #222 !important;
  margin-bottom: 2px !important;
  margin-top: 10px !important;
}
@media only screen and (min-width: 768px) {
  .container-ct-frm-drk {
    padding-bottom: 10px;
    background: white;
    mix-blend-mode: normal;
    border: 2px solid #decfcf;
    box-sizing: border-box;
    box-shadow: 0px 18px 26px rgb(74 63 63 / 25%);
    border-radius: 57px;
    padding: 30px;
  }
  .content-box{
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 10%;
    margin-right: 10%;
  }
  p.the-title_contactUs{
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    margin-left: 4%;
    color: #193f6a;
  }
  p.ct-frm-lbl {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #3f3a3a;
    margin-left: 7px;
  }
  .form-contact {
    margin-top: 4%;
  }
}
@media only screen and (min-width: 1000px) {
  .container-cmmon {
    padding-left: 55px;
    padding-right: 55px;
  }
}

.cont-text {
  margin-left: 21px;
  margin-top: 24px;
}

.the-title {
  font-family: 'Lato', sans-serif;
  color: #d4943f;
}
p.err_txt {
  padding-left: 15px;
}

.the-title {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 21px;
}

.new_container {
  margin-top: 19px;
  margin-bottom: 25px;
  background: white;
  border-radius: 30px;
  -webkit-box-shadow: 5px 2px 36px 5px #d7d8da;
  -moz-box-shadow: 5px 2px 36px 5px #d7d8da;
  height: 80%;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 10px;
  padding-bottom: 25px;
}


@media only screen and (min-width: 1700px) {
  .container-cmmon {
    padding-left: 70px;
    padding-right: 70px;
  }
}

img.wh-logo {
  height: 29px;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 7px;
  margin-top: -5px;
}

p.txt-content {
  display: flex;
}

p.txt-content {
  font-family: 'Lato', sans-serif;
  color: #3f3a3a !important;
  line-height: 30px;
  display: inline;
}
.bgImage{
  padding: 2%;
}

.the-title {
  font-family: 'Lato', sans-serif;
  color: #d4943f;
}

.the-title.left {
  text-align: left;
  margin-left: 22px;
}


.input_contact {
  background: #f2ecec;
  border-radius: 25px;
  padding-left: 19px !important;
  border: none;
  width: 98%;
  min-height: 47px;
  margin-top: 10px;
  padding-right: 20px !important;
}

.input-style {
  background: #f2ecec;
  border-radius: 45px;
  height: 45px !important;
  padding-left: 10px;
}

ion-col.sl-cl.md.hydrated {
  align-items: center;
  display: flex;
  justify-content: center;
}

ion-col.sl-cl.ios.hydrated {
  align-items: center;
  display: flex;
  justify-content: center;
}