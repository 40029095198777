@media screen and (max-width: 767px) {
    .update-left-div{
        width: 100%;
    }
    .update-right-div{
        width: 100%;
    }
}
@media screen and (min-width: 767px)  {
    .update-left-div{
        width: 95%;
        margin-right: 5%;
    }
    .update-right-div{
        width: 95%;
        margin-left: 5%;
    }
}
.update-input{
    border-radius: 5px;
    padding-left: 10px;
    width: 100%;
    border: 1px solid #bfbfbf;
    min-height: 48px;
    margin-bottom: 6%;
}
.update-input:focus{
    border: 1.5px solid #bfbfbf;
}
.update-input-disabled{
    border-radius: 5px;
    padding-left: 10px;
    width: 100%;
    border: 1px solid #bfbfbf;
    min-height: 48px;
    margin-bottom: 6%;
    background-color: #d5d5d5;
}

.container-form{
    padding-top: 2px;
    width: 60%;
    background: #fff;
    padding-left: 7px;
    border-radius: 44px;
    border: 1px solid black;
    margin-top:100px;
    margin-left: 550px;
    padding-bottom: 50px;
    
}
.row-form
{
    text-align: center;
    width: 200px;
    display:flex;
    margin: 10px;

}
.subscription-box{
    background-color: #d4943f;
    text-align: center;
    border: 1px solid #d4943f;
    border-radius: 30px;
    height: 120px;
    vertical-align: middle;
    margin-left: 70px;
    
}
button.requestSubmitEditOrder {
    width: 100%;
    height: 45px;
    border-radius: 0px;
    color: #00AEEC;
    background-color: #00AEEC;
    font-size: 17px;
    font-family: 'Lato', sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    padding-top: 10px;
  }
.subscription-box1{
    text-align: center;
    border: 1px solid #d4943f;
    border-radius: 30px;
    height: 120px;
    vertical-align: middle;
    margin-left: 400px;
}

.text-amount{
    color: #f6e4ad;
    font-weight: 600;
    font-family: 'Courier New', Courier, monospace;
    width: 157px;
    font-size: 22px;
    padding-bottom: 13px;
    margin-top: 6px;
    justify-content: center;
}
.text-amount1{
    color:#d4943f;
    font-weight: 600;
    font-family: 'Courier New', Courier, monospace;
    width: 157px;
    font-size: 22px;
    padding-bottom: 13px;
    margin-top: 6px;
    justify-content: center;
}

.text-amount-detail{
    color:#f6e4ad;
    font-size: 11px;
    margin-top: 2px;
}
.text-amount-detail1{
    color: #d4943f;
    font-size: 11px;
    margin-top:2px ;
}
.input_box{
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
        width: 100%;
    border-bottom:1 px solid green;

}
.row-update{
    padding-top: 30px;
    padding-bottom: 20px;
}
.label-update{
    font-size: 13px;
}
.col-update{
    margin-left:20px;
}
input:focus{
    border:green ;
}

.update-creation{
   padding-left: 30px;  
}
.checkbox-size{
    height: 15px;
    width:15px;
}
 input[type="checkbox" ]:checked{
    background-color:#d4943f ;
}
.check-update{
    font-size: 14px;
    margin-left: 10px;
    color: #0f3054;
}