@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
.termscontainer{
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 2%;
}
p.terms-Txt{
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.01em;
    font-weight: 400;
    font-size: 13.8px;
}
.highlight-txt{
    font-weight: 600;
}
p.terms-Title{
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.01em;
    font-weight: 600;
    font-size: 16px;
}
li{
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.01em;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 10px;
}