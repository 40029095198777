
@media only screen and (max-width: 767px) {
  .footer_contant {
    background: linear-gradient(220deg, rgba(147,219,243,1) 0%, rgba(180,229,246,1) 4%, rgba(230,245,250,1) 13%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 37%, rgba(255,255,255,1) 77%, rgba(232,247,252,1) 86%, rgba(190,233,248,1) 97%, rgba(147,219,243,1) 100%);
    padding: 4%;
    padding-right: 5%;
    width: 103vw !important;
  }
}
@media only screen and (min-width: 768px) {
  .footer_contant {
    background: linear-gradient(220deg, rgba(147,219,243,1) 0%, rgba(180,229,246,1) 4%, rgba(230,245,250,1) 13%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 37%, rgba(255,255,255,1) 77%, rgba(232,247,252,1) 86%, rgba(190,233,248,1) 97%, rgba(147,219,243,1) 100%);
    padding: 4%;
    padding-right: 5%;
    width: 102vw !important;
  }
}
.footer_logo {
  height: 3em!important;
  object-fit: contain;
  margin-bottom: 7%;
}
.hr-footer {
  width: 159px;
  margin-top: 25px;
  margin-bottom: 25px;
  color: black;
}
.links {
  margin-top: -2px;
}
.links_text {
  color: #074B6F !important ;
  font-size: 17px;
  text-decoration: none;
}
.footer_info {
  color: black;
  font-size: 17px;
  text-align: left;
}
a:link {
  background-color: transparent;
  text-decoration: none;
}

.links_text:hover {
  color: #074B6F !important ;
  opacity: 0.7;
}
