@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.minSidebar{
  min-height: 100vh;
  z-index: 99999999;
  overflow:  hidden !important;
}
.menu-logo {
  width: 160px;
  margin-top: 12%;
  margin-left: 5%;
  margin-bottom: 12%;
}
.menu-item {
  margin-top: 3px;
  margin-bottom: 3px;
}
.btn {
  background-color: transparent;
  border: 0px solid;
  color: #fff !important;
  font-size: 14px !important;
  padding-left: -50px;
}
.btn:hover {
  color: #ba7105 !important;
}
.submenu-dot {
  margin-right: 14px;
}
span.sidebar-item-txt {
  color: #fff !important;
  font-size: 16px;
  padding-left: 0px;
  font-weight: 600;
  margin-top: 5px;
  display: block;
  margin-left: 0px;
}

.aligner {
  background-color: white;
}
