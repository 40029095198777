@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

@media only screen and (min-width: 768px) {
  .form-container-contact {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 3%;
  }
  button.sub-btn-contactbtn {
    width: 140px;
    font-weight: 600;
    margin-top: 5%;
    border-radius: 5px !important;
    height: 45px;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.7px;
    background-color: #00AEEC;
    transition-duration: 0.5s;
  }
  .contact-contacts-new {
    background-color: #00476b;
    padding: 5%;
    padding-top: 4%;
    margin-top: 4%;
  }
  .desktopView_home {
    width: auto;
    margin-top: 35px;
  }
  p.txt_randomCity{
    font-size: 40px;
    margin-bottom: 15px;
    color: #00476b;
    margin-top: 20px;
    font-family: 'Lato', sans-serif !important;
    font-weight:900;
  }
  p.title-home-about-services {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    letter-spacing: 0.01em;
    color: #00476b;
    font-size: 44px;
    font-weight: 600;
    margin-bottom: -25px;
    margin-top: 35px !important;
  }
  .youtube_homepage {
    display: block;
  }
  .youtube_homepage_mobile {
    display: none;
  }
  button.visit-site-blue {
    width: 140px;
    height: 40px;
    border-radius: 20px;
    color: white;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    background: #00476b;
    filter: drop-shadow(5px 10px 7px rgba(255, 243, 243, 0.25));
    border-radius: 45px;
    margin-bottom: 17px;
    margin-top: 20px;
    font-weight: 600;
  }

  .icons-new {
    margin-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .home-txt-content-new {
    font-family: 'Lato', sans-serif;
    color: white !important;
    font-size: 17px;
    font-weight: 600;
  }
  #mobileService {
    display: none;
  }
  #mobileService-img {
    display: none;
  }
  #mobileYT {
    display: none;
  }
  #deskstopYT {
    display: block;
  }
  .bookModal {
    margin-left: 6%;
    margin-bottom: 5%;
  }
  .roundImageTop {
    height: 135px;
  }
  .text-pop {
    margin-top: 15%;
  }
}
button.sub-btn-contactbtn:hover{
  /*background-color: #193f6a;*/
  color: #fff !important;
}
@media only screen and (max-width: 767px) {
  button.sub-btn-contactbtn {
    width: 140px;
    font-weight: 600;
    margin-top: 2%;
    margin-bottom: 4%;
    transition-duration: 0.5s;
    border-radius: 4px !important;
    height: 48px;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    background-color: #00AEEC;
  }
  .contact-contacts-new {
    background-color: #00476b;
    padding: 4%;
    margin-top: 4%;
  }
  .form {
    margin-top: 0px !important;
    border-radius: 4px !important;
    justify-content: center;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  p.txt_randomCity{
    font-size: 30px;
    margin-bottom: 15px;
    color: #00476b;
    margin-top: 20px;
    font-family: 'Lato', sans-serif !important;
    font-weight:900;
  }
  .desktopView_home {
    width: auto;
  }
  p.title-home-about-services {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    letter-spacing: 0.01em;
    color: #00476b;
    font-size: 44px;
    font-weight: 600;
    margin-top: 50px;
    margin-bottom: 0px !important;
  }
  .youtube_homepage {
    display: none;
  }
  .youtube_homepage_mobile {
    display: block;
  }
  button.visit-site-blue {
    width: 140px;
    height: 40px;
    border-radius: 20px;
    color: white;
    font-size: 16px;
    margin-left: 5px;
    font-family: 'Lato', sans-serif;
    background: #00476b;
    filter: drop-shadow(5px 10px 7px rgba(255, 243, 243, 0.25));
    border-radius: 45px;
    margin-bottom: 10px;
    margin-top: 5px;
    font-weight: 600;
  }
  
  .home-abt-box {
    margin-left: -10%;
  }
  .txt_Title {
    padding-left: 2%;
  }
  .txt_subtitle {
    padding-left: 2%;
  }
  .home-txt-content-new {
    font-family: 'Lato', sans-serif;
    color: white !important;
    font-size: 13px;
    font-weight: 600;
  }
  .form-container-contact {
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 3%;
  }
  .icons-new {
    margin-top: 2%;
    margin-right: 3%;
  }
}
.grad-box {
  margin-top: -40px;
}
@media only screen and (min-width: 900px) {
  .form {
    padding-left: 30px !important;
    padding-right: 30px !important;
    justify-content: center;
    margin-bottom: 30px;
  }
  
}
.onHoverCursor{
  cursor: pointer !important;
}
.con-box {
  max-width: 100vw !important;
  background: rgb(147,219,243);
background: linear-gradient(220deg, rgba(147,219,243,1) 0%, rgba(180,229,246,1) 4%, rgba(230,245,250,1) 13%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 37%, rgba(255,255,255,1) 77%, rgba(232,247,252,1) 86%, rgba(190,233,248,1) 97%, rgba(147,219,243,1) 100%);
}
::placeholder {
  color: black;
}
.Modal {
  overflow: hidden;
}
.modalHeader {
  background-color: #363636;
  height: 85px;
}
.dropdown-item-button {
  width: 100%;
  background-color: #193f6a;
}
a {
  color: #fff !important;
  font-weight: 600;
}
a:hover {
  color: #fff !important;
}
@media only screen and (max-width: 629px) {
  .form {
    margin-top: 0%;
  }
}
.form {
  align-items: center;
  align-content: center;
}
.form {
  margin-top: -17%;
}
.form-e-contact {
  background: hsl(0, 0%, 90%);
  mix-blend-mode: normal;
  box-sizing: border-box;
  box-shadow: 0px 18px 26px rgb(74 63 63 / 25%);

  /* margin-top: 29
 px
 ; */
  padding-top: 50px;
  padding-left: 10px;
  padding-right: 20px;
  margin-bottom: 40px;
  width: 140%;
}

p.send-mssge {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* line-height: 45px; */
  /* display: flex; */
  /* align-items: center; */
  letter-spacing: 0.01em;
  color: whitesmoke;
  text-align: center;
}

.txt-in {
  margin-left: 4%;
}
@media screen and (min-width: 767px) {
  .about-desktop-info {
    margin-left: -95px;
    width: 118%;
  }
  .flatsin-div {
    margin-left: 9%;
    margin-right: 9%;
    width: 82%;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .fab-btn-hvr{
    z-index: 99999999;
    position: fixed;
    top: 100;
    right: 0;
    margin-top: 80vh;
    margin-right: 5%;
  }
}
@media screen and (min-width: 900px) {
  .fab-btn-hvr{
    z-index: 99999999;
    position: fixed;
    top: 100;
    right: 0;
    margin-top: 70vh;
    margin-right: 5%;
  }
}
.GrClose-icons{
  color: #fff !important;
}
.BsWhatsapp-icons{
  color: #00476b !important;
}
@media screen and (max-width: 767px) {
  .txt-in {
    display: none;
  }
  .fab-btn-hvr{
    z-index: 9;
    position: fixed;
    top: 100;
    right: 0;
    margin-top: 70vh;
    margin-right: 7%;
  }
  .flatsin-div {
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 4%;
  }
  #mobileService {
    display: block;
    margin-top: 7%;
  }
  #mobileService-img {
    display: block;
  }
  #mobileYT {
    display: block;
  }
  #deskstopYT {
    display: none;
  }
}
p.ct-frm-lbl {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: white;
  margin-bottom: -4px;
}
p.ct-frm-lbl_new {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: white;
  margin-bottom: -4px;
}
p.new_ct-frm-lbl {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 10px;
  letter-spacing: 0.01em;
  color: #3f3a3a;
  margin-left: 11px;
  margin-bottom: 5px;
  margin-top: 5%;
}
input.input-style::placeholder{
  color: rgb(150, 150, 150);
}
span.errorText_ContactUs{
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  letter-spacing: 0.01em;
  color: red;
  margin-top: 5px;
  margin-left: 7px;
  margin-bottom: 20px;
}
.in-cus-ct {
  background: #f2ecec;
  border-radius: 25px;
  padding-left: 10px;
}

.messageInput {
  background: #f2ecec;
  border-radius: 25px;
  padding-left: 18px;
  border: none;
  width: 98%;
  margin-top: 10px;
  min-height: 75px;
}
.input-new-contact {
  background: whitesmoke;
  padding-left: 19px !important;
  border: none;
  width: 99%;
  min-height: 47px;
  margin-top: 3px;
  padding-right: 15px !important;
  border-radius: 4px;
  margin-bottom: 10px;
}

textarea.input-new-contact-message {
  padding-top: 10px;
}

.input-new-contact-message {
  background: whitesmoke;
  padding-left: 19px !important;
  border: none;
  width: 99%;
  height: 90%;
  padding-top: 5px;
  min-height: 43px;
  margin-top: 3px;
  padding-right: 15px !important;
  border-radius: 4px;
}
.selectCity {
  margin-top: 15px;
}
input.input_ {
  /* background: red; */
  background: #f2ecec;
  border-radius: 25px;
  padding-left: 18px;
  border: none;
  width: 100%;
  padding-top: 5px;
  min-height: 43px;
}
input.loginInput {
  /* background: red; */
  background: #f2ecec;
  border-radius: 45px;
  padding-left: 18px;
  border: none;
  width: 100%;
  padding-top: 5px;
  min-height: 43px;
}
p.loginErrorTxt {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  letter-spacing: 0.01em;
  color: #d9a054;
}
span.validateErrorTxt {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  letter-spacing: 0.01em;
  color: red;
}
.textarea_message {
  background: #f2ecec;
  border-radius: 25px;
  padding-left: 19px !important;
  border: none;
  width: 86%;
  padding-top: 5px;
  min-height: 70px;
  margin-top: 10px;
  padding-right: 20px !important;
  font-size: 16px;
}

input:focus,
textarea:focus {
  border: 0px solid #bfbfbf;
  outline-offset: 0px;
  outline: none;
}
button {
  background-color: #e8c99e;
  border: none;
  color: white;
  padding: 7px 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 7%;
  cursor: pointer;
  border-radius: 60px;
}
.mnu-ctsrvv {
  background-attachment: revert;
  background-position: initial;
  background-image: none !important;
  /* background: #d4943f; */
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 271px;
}

.mnu-ctsrvvv-service {
  width: 100%;
  padding: 5%;
  height: 100%;
  text-align: center;
  background-color: #f2f2f2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.inner-bluecircle-services {
  background-color: #00AEEC;
  height: 45px;
  width: 45px;
  border-radius: 95px;
  margin-top: 25px;
}
.services-card-icons {
  color: #ffffff;
  margin-top: 4px;
  align-items: center;
  align-self: center;
  justify-content: center;
}
p.title-ct-srvv {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  padding-top: 4%;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  /* display: flex; */
  align-items: center;
  justify-content: end;
  letter-spacing: 0.01em;
  color: black;
}
#nwbtn-id1 {
  margin-top: 60px;
  margin-right: 10px;
}
#nwbtn-id2 {
  margin-bottom: 70px;
}
#nwbtn-id3 {
  margin-top: -5px;
}
p.bk-nwbtn-service {
  cursor: pointer;
  background: #00476b;
  width: 90%;
  margin-left: 5%;
  font-size: 15px;
  color: white;
  margin-top: 40px;
  height: 38px;
  padding-top: 7px;
  border-radius: 3px;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}
p.txt-ct-srvv-service {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: black;
  margin-top: 26px;
  padding-left: 3%;
  padding-right: 3%;
  font-weight: 600;
  font-family: 'Lato', sans-serif;
}
.about_us {
  flex-direction: column;
  margin-left: 7%;
  margin-right: 7%;
}
.txt-about_us {
  color: #bb7005;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: -7px;
}
.info {
  margin-left: 7%;
  margin-right: 7%;
}
.info_txt {
  color: #777676;
  font-size: 18px;
}
.readmore {
  color: #737373;
}
.yt_video {
  margin-top: 3%;
}
.intro {
  margin-left: 10%;
  margin-right: 10%;
}
.intro-div {
  margin-top: 5%;
  flex-direction: row;
  display: flex;
}
.intro-block {
  width: 50%;
  background-color: #454246;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 30px;
}
.intro-block2 {
  width: 50%;
  background-color: #454246;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 30px;
  margin-left: 5%;
}
.intro-title {
  color: #fff;
  font-size: 20px;
}
.intro-txt {
  color: #fff;
  font-size: 16px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.services {
  flex-direction: column;
  margin-left: 4%;
  margin-top: 5%;
}
.services-title {
  color: 00476B;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: -7px;
}
.services-txt {
  font-size: 12px;
  margin-left: -7px;
}
.paymentIcon {
  height: 35px;
  width: 35px;
}
.contact-us {
  margin-top: 7%;
  margin-left: 4.5%;
  margin-right: 4.5%;
  margin-bottom: 7%;
}
.contact-us-title {
  flex-direction: column;
}
.paymentView {
  border: 1.5px solid #bfbfbf;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  padding-left: 3%;
  padding-top: 1.6%;
  padding-right: 3%;
  cursor: pointer;
}
.payment-view-gap {
  margin-top: 5%;
  margin-bottom: 5%;
}
.contact-us-txt {
  color: #bb7005;
  font-size: 34px;
  font-weight: 500;
  margin-bottom: -7px;
}
.contact-details {
  flex-direction: row;
  display: flex;
}
.contact-details-div {
  width: 54%;
  background-color: #454246;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 7%;
  height: 30%;
  margin-right: 1%;
}
.contact-details-txt {
  color: #fff;
  font-size: 17px;
}
.email-icon {
  width: 10%;
}
.contact-form {
  margin: 10%;
}
.packageDiv {
  border: 2px solid #d9a054;
}
.innerPackageDivOn {
  border: 2px solid #d9a054;
  border-radius: 17px;
  height: 17px;
  width: 17px;
  margin-left: 30%;
}
.txt-package {
  text-align: center;
}
#modalText {
  font-family: 'Lato', sans-serif;
  color: #d4943f;
  font-weight: bold;
  padding-left: 0.5%;
  margin-right: 1.5%;
}

.visitSite-div {
  margin: 9%;
}
/* HERE */
.sc-hm-one {
  background-color: white;
}
.text-pop {
  float: left;
  text-align: left;
}

.home-why-us-title {
  font-family: 'Lato', sans-serif;
  color: #d4943f;
}
p.txt_Title {
  color: #00476b !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 44px !important;
  margin-top: 46px !important;
  line-height: 59px;
  font-weight: bold;
}
p.txt_subtitle {
  font-size: 16.5px;
  font-weight: 600;
  color: gray;
  font-family: 'Lato', sans-serif;
}
p.txt-title-modal {
  color: #193f6a;
  font-size: 28px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}
p.txt-title-modal-amt {
  color: #193f6a;
  font-size: 32px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  margin-top: -25px;
}
p.txt-title-modal-amt-months {
  color: #193f6a;
  font-size: 23px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  margin-top: -31px;
}
.home-why-us-title {
  font-family: 'Lato', sans-serif;
  color: white;
  margin-bottom: 10px;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}
p.txt_.Title.Second {
  font-size: 28px !important;
  margin-top: 49px;
  font-family: 'Lato', sans-serif !important;
  line-height: 35px;
  margin-bottom: 48px;
}
p.title-content-h {
  font-family: 'Lato', sans-serif;
  font-size: 33px;
  color: #d4943f;
  font-weight: bold;
  margin-top: 4px;
}
.home-abt-box {
  margin-left: -5%;
}
.content-box {
  margin-top: -4px;
}
p.title-home-about {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  letter-spacing: 0.01em;
  color: #fff;
  font-size: 40px;
  font-weight: 600;
}
.ads-call-icons-new {
  cursor: pointer;
  color: white;
}
.ads-call-icons-new-email {
  cursor: pointer;
  color: white;
}
p.txt_.one {
  color: #193f6a !important;
  font-family: 'Lato', sans-serif;
  font-size: 39px !important;
  font-weight: 600;
  /* font-weight: bold !important; */
  margin-top: 31px;
  margin-left: 8px;
}
.Ads-row {
  padding-left: 3%;
  color: #00476b;
}
.Ads-Btn {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  background: #00476b;
  filter: drop-shadow(5px 10px 7px rgba(255, 243, 243, 0.25));
  border-radius: 45px;
  margin-top: 1px;
  float: right;
  font-weight: 600;
  margin-right: 0px;
}
h2.Ads-style {
  color: #00476b;
  font-size: 44px;
  font-family: 'Lato', sans-serif;
}
p.txt_.two {
  color: #193f6a !important;
  font-family: 'Lato', sans-serif;
  font-size: 19px !important;
  font-weight: 600 !important;
  margin-top: -20px !important;
  margin-left: 13px;
}
p.txt_.three {
  color: #193f6a !important;
  font-family: 'Lato', sans-serif;
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 12px !important;
  margin-top: -11px !important;
  margin-left: 14px;
}
p.txt_.subTitle {
  color: black !important;
  font-family: 'Lato', sans-serif;
  font-size: 14px !important;
  line-height: 23px !important;
  margin-top: 15px !important;
  margin-left: 2px !important;
}
p.txt_.one {
  margin-top: 56px !important;
}
p.txt_.subTitle.Second.p {
  margin-top: 34px !important;
}

p.txt_.Title.Second {
  font-size: 28px !important;
  margin-top: 49px;
  font-family: 'Lato', sans-serif !important;
  line-height: 35px;
  margin-bottom: 48px;
}

button.exclusiveBtn {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  color: white;
  margin-left: 1%;
  background: #00AEEC !important;
  filter: drop-shadow(5px 10px 7px rgba(255, 243, 243, 0.25));
  margin-top: -5px;
  transition-duration: 0.5s;
  font-size: 16.5px;
}
button.exclusiveBtn:hover{
  background-color: #193f6a !important;
  color: #fff !important;
}
.aln-hm-cn {
  float: right;
  margin-top: 22px;
}
.aln-itms-cntr.thrr {
  text-align: center;
  margin-top: -25px;
  margin-left: 92px;
}
/* about us*/

.container-cmmon {
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 42px;
}
@media only screen and (max-width: 999px) and (min-width: 698px) {
  .container-cmmon {
    padding-left: 45px;
    padding-right: 45px;
  }
  .hr-in-vrtl {
    margin-top: 30%;
    margin-right: 10%;
    padding-left: 1%;
  }
}

@media only screen and (min-width: 1000px) {
  .container-cmmon {
    padding-left: 55px;
    padding-right: 55px;
  }
}

@media only screen and (min-width: 1700px) {
  .container-cmmon {
    padding-left: 70px;
    padding-right: 70px;
  }
}

.cont-text.ft-ct {
  margin-top: 56px;
}
@media only screen and (max-width: 612px) {
  p.txt_.one {
    margin-top: 56px !important;
  }

  .cont-text.ft-ct {
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px !important;
    padding-right: 10px !important;
  }
}
.cont-text.ft-ct {
  background: #5a564f;
  display: grid;
  padding-top: 28px;
  padding-bottom: 20px;
  padding-left: 20px;
}
@media only screen and (min-width: 900px) {
  img.entr-gr-one {
    margin-right: 20px;
    margin-bottom: -74px;
  }
}
@media only screen and (min-width: 1300px) {
  .hp_contain.Two {
    margin-left: -99px !important;
    margin-top: 24px;
    padding-bottom: 1px;
  }

  .hp_contain.Four {
    margin-left: -99px !important;
    margin-top: 20px;
    padding-bottom: 1px;
  }
}
.home-txt-content.drk {
  font-family: 'Lato', sans-serif;
  color: #777676;
  font-size: 17px;
  float: center;
}
.rdMore {
  padding-bottom: 4px;
  cursor: pointer;
  color: #737373;
  font-weight: 500;
  font-size: 16.6px;
  font-family: 'Lato', sans-serif;
}
.pop-box {
  background-repeat: no-repeat, repeat;
  background-position: right top;
}
@media only screen and (max-width: 850px) {
  .pop-box.top-contain {
    margin-top: 6%;
    width: 60%;
  }

  .contact-contacts {
    padding-top: 0px !important;
    background-color: #00476b;
    padding-left: 5px;
    padding-right: 5px;
  }
  .home-abt-box {
    margin-left: 2px;
  }
}

@media only screen and (min-width: 1000px) {
  .contact-contacts {
    padding-left: 55px;
    padding-right: 55px;
    background-color: #00476b;
  }
}
.contact-contacts {
  padding-top: 60px;
  background-color: #00476b;
  padding-bottom: 60px;
  margin-top: 10%;
}
@media only screen and (max-width: 1091) {
  .pop-box.top-contain {
    margin-top: 22%;
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 900px) {
  .pop-box.top-contain {
    margin-top: 100px !important;
  }
}
.pop-box.top-contain {
  height: 100%;
  /* margin-top: auto; */
  margin-bottom: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 1356px) {
  .pop-box.top-contain {
    margin-left: 121px;
  }
}
.pop-box.top-contain {
  margin-top: 57px !important;
  text-align: center;
}
.hp_contain {
  background: #444346;
  border-radius: 34px;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 5px;
  padding-top: 17px;
  padding-bottom: 24px;
}
.hp_contain.Two {
  margin-left: -50px;
  margin-top: 24px;
  padding-bottom: 1px;
  margin-top: 22px;
}
.hp_contain.Four {
  margin-left: -50px;
  margin-top: 20px;
  padding-bottom: 1px;
}
img.entr-gr-two {
  margin-top: 12px;
  height: 350px;
}
@media only screen and (min-width: 700px) {
  img.entr-gr-one {
    margin-top: 78px;
  }
}
img.entr-gr-one {
  margin-right: 20px;
  height: 250px;
}
img.entr-gr-three {
  height: 158px;
  margin-left: 60%;
}
p.home-txt-content.tx {
  color: #fff8f8 !important;
  font-size: 15px;
  font-family: 'Lato', sans-serif;
  line-height: 30px;
}
p.home-txt-content {
  font-family: 'Lato', sans-serif;
  text-align: left;
  margin-top: -7px;
  color: white;
}

.hr-in-vrtl {
  margin-top: 30%;
  margin-right: 10%;
  padding-left: 3%;
}

.colOrderView.col {
  margin: 10px;
}
img.roundImageTop {
  margin: 10px;
}
.check-icon {
  height: 85px;
  width: 85px;
  margin-top: 5%;
}
p.orderComplete-txt {
  color: #5a564f !important;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
}
p.orderDetails-header {
  color: #5a564f !important;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  line-height: 30px;
  font-weight: 600;
}
.services-img-slider-homepage {
  height: 400px;
  padding-top: 10%;
}
.search-card {
  margin-top: 3%;
}

p.home-txt-content-new {
  margin-top: 8px;
  font-family: 'Lato', sans-serif;
}

button.Ads-Btn {
  margin-top: 23px;
}

@media only screen and (min-width: 1496px) {
  button.Ads-Btn {
    margin-right: 20px;
  }
}
.react-multiple-carousel__arrow{
  margin-top: -20px;
}
.react-multiple-carousel__arrow:hover{
  background-color: #00476b;
}
