@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.SideBarPosition{
  z-index: 999999;
  position: fixed;
}
.sidebar_Shadow{
  min-width: 28.3vw;
  object-fit: contain;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
}
.nav-change {
  color: #02486c !important;
  font-weight: 600;
  font-size: 17px;
}
.nav-change-drop {
  color: #02486c !important;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.nav-change-drop:hover {
  opacity: 0.7;
  color: #00AEEC !important;
}
.nav-change:hover {
  color: #00AEEC !important;
}
.left-hr {
  width: 100%;
}
span.forgotPass-hover {
  cursor: pointer;
  text-align: right;
}
.dropdown_icon{
  float:right;
  margin-left: 5px;
}
.categories-hover-div{
  padding-bottom: 15px;
  width: 100px;
  position: absolute;
  background-color: #fff;
  margin-top: 1.7rem;
  margin-left: 11rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  z-index: 99;
  padding-top: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.logoutModal-txt{
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.categories-hover-div2{
  padding-bottom: 15px;
  width: 100px;
  position: absolute;
  background-color: #fff;
  margin-top: 2rem;
  margin-left: 19rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  z-index: 99;
  margin-right: 3%;
  padding-top: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
border-radius-5 div {
  border-radius: 0px !important;
  align-items: center;
}
p.loginModalTxt {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  align-items: center;
  letter-spacing: 0.01em;
  color: #ba7105;
  margin-bottom: 5%;
  margin-top: 5%;
}
p.navbar_txt {
  color: #02486c;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
  margin-top: 2%;
}


.signupLink{
  color: #00AEEC !important;
}
.signupLink:hover{
  color: #113d4d !important;
}
p.navbar_txt_reserve {
  color: #02486c;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-weight: 600;
  font-size: 17px;
  margin-top: 2%;
}
p.navbar_txt:hover {
  color: #00AEEC;
}
.closeIcon {
  float: right;
  cursor: pointer;
}
.closeIcon_sidebar {
  cursor: pointer;
  color: #02486c;
}
p.loginErrorTxt {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  letter-spacing: 0.01em;
  color: red;
}
.logo {
  height: 30px;
  width: 60px;
  border-radius: 7px;
}
.side-bar {
  display: none;
  color: #fff;
}
#new-alignment-navbar {
  display: flex;
  padding-top: 10px;
  float: right;
  margin-right: 1.3%;
}
.nav-change-about {
  color: #02486c !important;
  font-weight: 600;
  font-size: 17px;
  display: block;
}
.nav-change-about:hover {
  color: #00AEEC !important;
}

.nav-change-notification {
  color: #02486c !important;
  font-weight: 600;
  font-size: 17px;
  display: block;
}

@media screen and (max-width: 1140px) {
  .nav-change-about {
    display: none;
  }
  .nav-change-notification {
    display: none;
  }
  
}
@media screen and (max-width: 599px) {
  .border-radius-2 div {
    border-radius: 40px !important;
  }
  .border-radius-5 div{
    border-radius: 40px !important;
  }
  .border-radius-1 div{
    border-radius: 30px !important;
  }
  .search-modal-mobile div{
    border-radius: 15px;
  }
}
@media screen and (min-width: 600px) {
  .border-radius-2 div {
    border-radius: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .border-radius-5 div{
    border-radius: 35px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .border-radius-1 div{
    border-radius: 30px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .side-bar {
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    font-size: 1.8rem;
    cursor: pointer;
    justify-content: flex-start;
  }
  
  .sidebar-icon {
    height: 22px;
    width: 30px;
  }
  .mobile-side-img-div {
    padding-top: 10px;
    width: 85%;
    justify-content: center;
    align-self: center;
  }
  .mobile-side-img-div2 {
    width: 100%;
    padding-left: 35%;
    padding-top: 25%;
  }
  .mobile-side-img-div3 {
    position:absolute;
    z-index: 99999999;
    right: 10%;
    float: left;
  }
}
.divider-Margin {
  margin-top: 3%;
  margin-bottom: -5%;
}
input.forgetPassword_input{
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #bfbfbf;
  min-height: 45px;
  margin-bottom: 15px;
}
input.forgetPassword_input:focus{
  border: 2px solid #00AEEC;
}
input.forgetPassword_input::placeholder{
  color: #7c7c7c;
}
p.errorText_forgetPassword{
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  letter-spacing: 0.01em;
  color: red !important;
  margin-top: -5px !important;
  margin-left: 7px;
}