body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /** IE11 */
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.custom-width {
  width: 50% !important; /* Example width */
}

/* Optionally, you can make it responsive */
@media (max-width: 992px) {
  .custom-width {
    width: 100% !important; /* Full width on small screens */
  }
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 8px 16px;
  border: 1px solid #00AEEC !important;
  color: #575757 !important;
  text-decoration: none;
  cursor: pointer;
}

.page-link:hover {
  background-color: #00AEEC !important;
  color: white !important;
}

.active .page-link {
  background-color: #00AEEC !important;
  color: white !important;
  border: none;
}
