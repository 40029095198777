.bg-gradient {
    

    background-image:  url('./Group\ 16.svg') !important;
    background-size: cover; /* Ensures the image covers the entire element */
    background-position: center; /* Ensures the image is centered */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    align-content: center;
  }


.min-vh-453{
    min-height: 453px;
}
.form-card{
    width: 393px;
height: 319px;
 
border-radius: 15px ;
 

}

.form-text{
    font-family: Inter;
font-size: 12px;
font-weight: 300;
line-height: 18px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.text-div{
    width: 441px;
height: 227px;
 

}
#verificationModalLabel{

  
font-size: 24px;
font-weight: 700;
line-height: 18px;
 
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: black;
}
.verification-modal{
    width: 433px;
height: 319px;
 
border-radius: 15px !important ;
 

}
.modal-dialog-centered{
    display: flex;

    justify-content: center;
}
.modal-header{
    
    padding: 30px 44px;
    
}.modal-body{
    padding: 0px 44px;
    >p{
     
font-size: 12px;
font-weight: 300;
line-height: 18px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #3F3A3A;

    }
}

.sumt-btn{
    width: 86px !important;
height:38px !important;
 
padding: 10px 16px 10px 16px;
gap: 8px;
border-radius: 6px  ;
 background-color: #344F01 !important;

}